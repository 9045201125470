import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { updateShipmentMutation } from '../../../api';
import { PATHS } from '../../../constants/paths';
import { TOAST_TIME } from '../../../constants/toastNotifications';
import {
  calculateUniversalDate,
  HANDLE_FILE_SIZE_CHECK,
  has24HoursPassed,
  PARSIFY,
} from '../../../helpers/helpers';
import useApi from '../../../hook/useApi';
import { useHookFormSubmission } from '../../../hook/useHookFormSubmission';
import { editShipmentSchema } from '../schema/editShipmentSchema';
import axios from 'axios';
import _ from 'lodash';
import {
  getCarriersQuery,
  getShipmentTypesQuery,
} from '../../../api/services/admin.service';
import { setAddressAction } from '../../../store';

export const useEditShipment = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [toAddress, setToAddress] = useState(null);
  const [declaredValue, setDeclaredValue] = useState('');
  const [customerDetailModalOpen, setCustomerDetailModal] = useState(false);

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const [fieldsFromGoogle, setFieldsFromGoogle] = useState(null);

  const { handleSubmit, register, errors, trigger, setValue, watch } =
    useHookFormSubmission(editShipmentSchema);

  const { shipments } = useSelector((state) => state);
  const { addresses } = useSelector((state) => state);

  const [isLoading, setIsLoading] = useState(false);
  const [update, updateLoading] = useApi(updateShipmentMutation);
  const [merchandiseTypeOptions, setMerchandiseTypeOptions] = useState([]);
  const [getCarriers] = useApi(getCarriersQuery);
  const [getShipmentTypes] = useApi(getShipmentTypesQuery);
  const [carriers, setCarriers] = useState([]);
  const [isValueEditable, setIsValueEditable] = useState(false);

  const [fromAddresses, setFromAddresses] = useState([]);
  const [selectedFromAddress, setSelectedFromAddress] = useState(null);
  const [errFromAddress, setErrFromAddress] = useState(null);

  const user = JSON.parse(localStorage.getItem('login-user'));
  const rate =
    user &&
    user.entity &&
    (selectedCountry == 'United States' || selectedCountry == 'USA'
      ? user.entity.rate
      : user.entity.internationalRate);

  let findEditSHipment = shipments?.allShipments.find(
    (shipment) => shipment.id === id
  );

  const [cleanDocuments, setCleanDocuments] = useState(
    findEditSHipment?.documents ? JSON.parse(findEditSHipment?.documents) : []
  );
  // if (findEditSHipment?.documents) {
  //   setCleanDocuments(
  //     // JSON.parse(findEditSHipment?.documents.replace(/\\"/g, '"'))
  //     JSON.parse(findEditSHipment?.documents)
  //   );
  // }

  useEffect(() => {
    const getData = async () => {
      const res1 = await getCarriers();
      setCarriers(res1?.data?.allCarriers);

      const res2 = await getShipmentTypes();
      setMerchandiseTypeOptions(res2?.data?.allShipmentTypes);
    };
    getData();

    const _fromAddresses = addresses?.addresses?.map((item) => {
      const address = PARSIFY(item?.address);
      return {
        ...item,
        id: Number(item.id),
        name: `${item.nickName} ( ${address?.street}, ${address?.city}, ${address?.state}, ${address?.country} ${address?.zipCode} )`,
      };
    });
    setFromAddresses(_fromAddresses);
    setSelectedFromAddress(
      _fromAddresses?.find(
        (e) => e?.nickName == findEditSHipment?.from?.nickName
      )
    );
  }, []);

  // useEffect(() => {
  // }, [addresses]);

  const handleUploadAttachmentsCancel = (file) => {
    setCleanDocuments(() => {
      return cleanDocuments.filter((attachment) => {
        return attachment.location !== file.location;
      });
    });
  };
  const handleUploadAttachments = async (files) => {
    setIsLoading(true);
    toast.loading('loading');

    const promises = [];
    const uploadedAttachments = [];

    Object.keys(files).forEach((key) => {
      const document = HANDLE_FILE_SIZE_CHECK(20, files[key]);

      if (document) {
        const formData = new FormData();
        formData.append('upload', files[key]);

        const promise = axios
          .post(`${process.env.REACT_APP_BACKEND_FILE_UPLOAD_URL}/fileUpload`, formData)
          .then((response) => {
            uploadedAttachments.push(response.data);
          })
          .catch((error) => {
            console.error('Error uploading file:', error);
          });

        promises.push(promise);
      }
    });

    try {
      await Promise.all(promises);
      toast.dismiss();
      setIsLoading(false);

      setCleanDocuments(_.flatten([...cleanDocuments, ...uploadedAttachments]));
    } catch (error) {
      console.error('Error uploading files:', error);
      toast.dismiss();
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (findEditSHipment) {
      setIsValueEditable(!has24HoursPassed(findEditSHipment?.createdAt));
      if (findEditSHipment.carrierId) {
        setValue('carrier', findEditSHipment.carrierId);
      }
      if (findEditSHipment.pickUpDate) {
        setValue('pickupDate', findEditSHipment.pickUpDate?.split('T')[0]);
      }
      if (findEditSHipment.deliveryDate) {
        setValue('deliveryDate', findEditSHipment.deliveryDate?.split('T')[0]);
      }
      if (findEditSHipment.totalValue) {
        setValue('declaredValue', declaredValue || findEditSHipment.totalValue);
        setDeclaredValue(declaredValue || findEditSHipment.totalValue);
      }
      if (findEditSHipment.shipmentType) {
        setValue('merchandiseType', findEditSHipment.shipmentType.id);
      }
    }
  }, [findEditSHipment]);

  const closeCustomerDetailModal = () => setCustomerDetailModal(false);
  const openCustomerDetailModal = () => setCustomerDetailModal(true);

  const getUpdateCustomerDetailData = (updatedCustomerDetail) => {
    setToAddress(updatedCustomerDetail);
  };

  const streetPlaceholder = () => {
    if (toAddress) {
      return toAddress;
    } else {
      return JSON.parse(findEditSHipment.to);
    }
  };

  const handleDeclaredValue = (value) => {
    setDeclaredValue(value);
  };

  const handleGoogleAutoComplete = (address) => {
    setFieldsFromGoogle(address);
  };

  function getTimeZone() {
    var offset = new Date().getTimezoneOffset(),
      o = Math.abs(offset);
    return (
      (offset < 0 ? '+' : '-') +
      ('00' + Math.floor(o / 60)).slice(-2) +
      ':' +
      ('00' + (o % 60)).slice(-2)
    );
  }

  const onSubmit = async (values) => {
    toast.dismiss();
    toast.loading('Shipment is updating. Please wait!');

    values.pickUpDate = calculateUniversalDate(
      `${values.pickupDate.toISOString()}${getTimeZone()}`
    );

    values.deliveryDate = calculateUniversalDate(
      `${values.deliveryDate.toISOString()}${getTimeZone()}`
    );

    const modifiedToAddress = {
      street: values.streetAddress,
      name: values.destinationName,
      city: selectedCity,
      state: selectedState,
      zipCode: values.zipCode,
      unitNo: values.unitNumber,
      reference: values.referenceNumber,
      country: selectedCountry,
    };

    const { data, errors } = await update({
      shipmentId: parseInt(findEditSHipment.id),
      ...values,
      to: JSON.stringify(modifiedToAddress) || findEditSHipment.to,
      fromLocationId: selectedFromAddress.id,
    });
    if (data) {
      navigate(PATHS.SHIPMENTS);
      toast.dismiss();
      toast.success('Shipment updated successfully.', { duration: TOAST_TIME });
    }
    if (errors) {
      toast.dismiss();
      toast.error('Failed to update', { duration: TOAST_TIME });
    }
  };

  const setLocation = (location) => {
    if (
      location &&
      location?.name &&
      selectedFromAddress?.name !== location?.name
    ) {
      dispatch(setAddressAction(location));
      toast.dismiss();
      toast.success(`${location?.name?.split('(')[0]} selected successfully`, {
        duration: TOAST_TIME,
      });
      setSelectedFromAddress(location);
    }
  };

  return {
    rate,
    data: findEditSHipment,
    handleSubmit,
    onSubmit,
    register,
    errors,
    setValue,
    closeCustomerDetailModal,
    openCustomerDetailModal,
    getUpdateCustomerDetailData,
    streetPlaceholder,
    customerDetailModalOpen,
    declaredValue,
    handleDeclaredValue,
    navigate,
    trigger,
    updateLoading,
    watch,
    fieldsFromGoogle,
    setFieldsFromGoogle,
    handleGoogleAutoComplete,
    isLoading,
    handleUploadAttachments,
    handleUploadAttachmentsCancel,
    documents: cleanDocuments,
    setSelectedCountry,
    setSelectedState,
    setSelectedCity,
    selectedCountry,
    selectedState,
    selectedCity,
    merchandiseTypeOptions,
    carriers,
    isValueEditable,
    fromAddresses,
    selectedFromAddress,
    setLocation,
  };
};
