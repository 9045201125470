import { useEffect } from "react";
import useApi from "../../../../hook/useApi";
import { fetchAllSubscriptionPlanQuery } from "../../../../api/services/tradeShowPolicy.service";
import { useDispatch } from "react-redux";
import { storeTradeShowAction } from "../../../../store";

export const useTradeShowCoverage = () => {
    const dispatch = useDispatch();
    const [
        fetchAllSubscriptionPlans,
        allSubscriptionPlansLoading,
        allSubscriptionPlansError,
        allSubscriptionPlansData,
    ] = useApi(fetchAllSubscriptionPlanQuery);

    useEffect(() => {
        fetchAllSubscriptionPlans();
    }, []);
    useEffect(() => {
        if (allSubscriptionPlansData?.AllSubscriptionPlans !== undefined && allSubscriptionPlansData?.AllSubscriptionPlans?.length > 0) {
            dispatch(storeTradeShowAction({ key: 'plan', value: allSubscriptionPlansData?.AllSubscriptionPlans[0] }))
        }
    }, [allSubscriptionPlansData?.AllSubscriptionPlans]);

    return { allSubscriptionPlansData, allSubscriptionPlansLoading, dispatch }
}