import {
  Elements,
  useElements,
  useStripe,
  PaymentElement,
} from '@stripe/react-stripe-js';

import { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { CustomButton } from '../../../../common/custom';
import useApi from '../../../../hook/useApi';
import { getListPaymentMethodsQuery } from '../../../../api';
import { storeAllPaymentsAction } from '../../../../store/slices/payments';
import { PATHS } from '../../../../constants/paths';
import { useLocation, useNavigate } from 'react-router-dom';

const stripePromise = loadStripe(process.env.REACT_APP_PUBLIC_STRIPE_PK);
// const stripePromise = loadStripe(
//   'pk_test_51NplQ9IIMspqXCj8YCWSmD623Nx8fG7U3HtEdVEyQ9hSVTS3dm22IGABFmRRTQZRidOur0g3XEkQTBFS0fQ9yW0A00FFL5BBSo'
// );

const StripeCardDetailsForm = ({ setupIntent, setError }) => {
  const options = {
    clientSecret: setupIntent?.client_secret,
    appearance: {},
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <SetupForm setError={setError} />
    </Elements>
  );
};

export default StripeCardDetailsForm;

const SetupForm = ({ setError }) => {
  const [getListPaymentMethods] = useApi(
    getListPaymentMethodsQuery,
    storeAllPaymentsAction
  );
  const { pathname } = useLocation()
  const user = JSON.parse(localStorage.getItem('login-user'));

  const fetchPaymentMethodsList = async () => {
    const { data, errors } = await getListPaymentMethods(
      user.location.stripeCustomerID
    );
  };
  const tradeShowAddPayment = pathname?.includes("trade-show");

  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [showWarning, setShowWarning] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return null;
    }
    const return_url = `${process.env.REACT_APP_PUBLIC_STRIPE_RETURN_URL}${tradeShowAddPayment ? "trade-show-property-coverage/" : ''}payment`
    console.log(return_url, 'return_url');

    const { data, error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url,
      },
    });
    console.log(data, 'card data')
    if (error) {
      setError(error.message);
    } else {
      console.error('This runs once. card details saved');
      fetchPaymentMethodsList();
    }
  };

  return (
    <form className='mt-10' onSubmit={handleSubmit}>
      <PaymentElement
        onChange={(event) => {
          if (event.value.type.includes('card')) {
            setShowWarning(true);
          } else {
            setShowWarning(false);
          }
        }}
        className='px-2'
      />
      {/* <div className='flex mt-5 items-center justify-center'> */}
      {/* <Button
          disabled={!stripe}
          type='submit'
          className='min-w-[200px]'
        >
          Add Payment Method
        </Button> */}
      {/* <CustomButton
          text=' Add Payment Method'
          disabled={!stripe} */}
      {/* // className='min-w-[200px]' */}
      {/* //   /> */}
      {/* // </div> */}{' '}
      <div className=' flex flex-col  md:flex-row gap-[18px]  md:gap-[32px] justify-end mt-[32px]'>
        <CustomButton
          type='button'
          onClick={() => {
            navigate(PATHS.PAYMENT);
          }}
          className='order-1 md:order-none bg-none rounded-[8px] border-[1px] border-foundation-brown  text-foundation-brown max-w-[160px] w-full h-[48px]'
          variant='outline'
          text='Cancel'
        />

        <CustomButton
          className='bg-foundation-brown rounded-[8px] text-white max-w-[160px] w-full h-[48px] '
          text='Save'
        />
      </div>
      {showWarning ? (
        <p className='py-6 text-xs font-semibold'>
          * Additional 3% on premium will be charged on Credit Card payments
        </p>
      ) : (
        ''
      )}
      {/* {errorMessage && <div>{errorMessage}</div>} */}
    </form>
  );
};
