import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ClientsPanelHeader, CustomHeading } from '../../../common/custom';
import { CustomIcon } from '../../../common/customAssets';
import { backIcon } from '../../../assets';
import CustomContainer from '../../../common/custom/CustomContainer';
import { useSelector } from 'react-redux';
import { FORMATE_AMOUNT } from '../../../helpers/helpers';

const policyData = [
  {
    title: "1. Insured Risk",
    desc: "Ceasta Assurance Inc. (CAI) provides transit insurance for new furniture, fine vintage furniture, fine art, new or vintage home accessories, and lighting to and from the designated exhibits.",

  },
  // {
  //   title: "2. Limits of Insurance",
  //   points: [
  //     `Maximum coverage up to ${FORMATE_AMOUNT(coverageAmount)}.  If exceeding the maximum limit of ${FORMATE_AMOUNT(coverageAmount)}, please call for approval. `,
  //     // "CAI provides coverage for damage caused during:",
  //   ],
  //   // subPoints: [
  //   //   "2.1 During transit",
  //   //   "2.2 On-site assembly or breakdown",
  //   //   "2.3 During exhibit events",
  //   //   "2.4 Negligence or oversight of exhibitors",
  //   // ],
  // },
  {
    title: "2.	Coverage:",
    desc: "CAI provides insurance coverage for the merchandise only during: Transit between the point of origin to the exhibit and back to the original location, including:",
    points: [
      "2.1.	On-site assembly or breakdown",
      "2.2.	During exhibit events",
      "2.3.	Negligence or oversight of exhibitors",
      "2.4.	Transit coverage for Signature Plan only.",
    ],
    // subPoints: [
    //   "A packing list and photographs of each exhibit item to be uploaded to the CAI portal at the time of purchase.",
    //   "The bill of lading from the carrier to be uploaded to the portal prior to shipping.",
    // ],
  },
  {
    title: "3.	Limits of insurance:",
    desc: "Coverage is subjected to the maximum coverage limit and single-item limit specified in the plan selected by the exhibitor.",

    // subPoints: [
    // ],
  },
  {
    title: "4.	Reporting Requirements: ",
    desc: "To purchase a tradeshow insurance policy CAI, the following documents must be provided:",
    points: [
      "4.1. A packing list and/or photographs of each exhibit item are to be uploaded to the CAI portal before shipping.",
      // "4.1.	 A packing list and/or photographs of each exhibit item are to be uploaded to the CAI portal prior to shipping.",
    ],
  },
  {
    title: "5.	Images Requirements: ",
    desc: "When reporting a claim, clear images showing the front and back of each insured furniture piece must be submitted, along with photos of the attached shipping label and delivery address.",
    points: [
      "5.1.	CAI reserves the right to deny coverage if the submitted images are unclear.",
    ],
  },
  {
    title: "6.	Packaging Requirements:",
    desc: "All furniture must be professionally packed and crated by the exhibitor and/or transporter."
  },
  {
    title: "7.	Insured Value: ",
    desc: "The insured value of the merchandise will be determined by the declared value provided by the policyholder. CAI is not responsible for verifying or guaranteeing the authenticity of insured goods."
  },
  // {
  //   title: "7. Reporting",
  //   points: [
  //     "All shipments must be reported through the online account.",
  //   ],
  //   subPoints: [
  //     "7.1 Same Day shipments: Cut-off time for same day shipments must be reported before 3 pm PST. If after please contact CAI for approval.",
  //     "7.2 Customers may cancel/edit their order within 24 hours of the shipment. notification with no cancellation fees.",
  //     "7.3 MDP, and DPP programs do not apply.",
  //     "7.4 CAI-approved customers who are legimate businesses may report shipments only.",
  //     "7.5 CAI reserves the right to refuse shipments and/or accounts that do not comply with CAI policies.",
  //   ],
  // },
  {
    title: "8.	Premium:",
    desc: "The payment method on file will be charged the premium immediately upon purchase. Prorated premiums will apply to the following situations:",
    points: [
      "8.1.	If the exhibitor cancels within 24 hours of the trade show, the premium will be partially refunded.",
      "8.2.	Cancellation more than 24 hours before the event, the premium will be refunded less than 3%.",
      "8.3.	A 3% credit card fee will be applied to the premium.",
      "8.4. Exhibitors are not eligible for refunds on premium payments if they do not attend the event."
    ],
  },
  // {
  //   title: "9. Deadline to File a Claim",
  //   points: [
  //     "The insured must submit a claim to CAI within 72 hours after the scheduled arrival date, upon determination of damage, theft, or loss.  Submit all necessary information, documentation, and evidence to support the claim.  If the furniture has been damaged, CAI will contact the shipping company to provide a damage report and necessary documentation, to process the claim. ",
  //     // "All claims must include all necessary documentation, evidence, and supporting information for the claim.",
  //     // "If merchandise has been damaged, CAI will request a damage report and any required documentation from the shipping company to process the claim.",
  //   ],
  // },
  {
    title: "9.	Pick-Up Confirmation: ",
    desc: " Coverage does not apply to items the purchaser/buyer picks up directly."
  },
  {
    title: "10.	Claim Filing Deadline:",
    points: [
      "10.1. Claims must be filed within 72 hours of the delivery date, or upon determination of damage, theft, or loss.",
      "10.2. All claims must include all necessary documentation, evidence, and supporting information for the claim.",
      "10.3. If merchandise has been damaged, CAI will request a damage report and any required documentation from the shipping company to process the claim.",
      // "10.1 Claim funds are to be paid to the CAI customer and not the recipient.",
      // "10.2  Policyholders may submit a certified appraisal conducted within the last 12 months. . CAI will accept the declared value if it is equal to or lower than the appraised value.  ",
      // "10.3 Appraisal and accessorial charges. The premium of the claim must be paid before opening the claim.",
    ],
    // notes: [
    //   "10.4 CAI reserves the right to hire a certified appraiser to determine the item’s market value.",
    //   "10.5 If the appraised value is lower than the declared value, CAI will only pay the appraised value and reimburse any excess premium paid.",
    //   "10.6 Policyholders may submit a certified appraisal conducted within the last 12 months. CAI will accept the declared value if it is equal to or lower than the appraised value.",
    // ],
  },
  // {
  //   title: "11. Covered Furniture:",
  //   points: [
  //     "CAI will only honor coverage for shipments that are picked up and with the confirmation timestamped, up to the declared value.    Furniture without Proof of Purchase, the appraised market value will apply.",
  //   ],
  // },
  {
    title: "11.	Required Documents to File a Claim: ",
    desc: "The following documents are required to file a claim: ",
    points: [
      "11.1. Claimant(s) must provide a receipt from the event organizer as proof of their participation.",
      "11.2. A signed invoice or receipt showing the original price and date of sale.",
      "11.3. Signed delivery order or receipt from the carriers or transporter if used for transit.",
      "11.4. If proof of value is unavailable, the maximum coverage is $5,000. ",
      "11.5. CAI reserves the right to hire a certified appraiser to determine the item’s market value.",
      "11.6. Claim payment will based on the appraised value if it is less than the declared value.",
      "11.7. Policyholders may submit a certified appraisal conducted within the last 12 months. CAI will accept the declared value if it is equal to or lesser than the appraised value.",
      "11.8. CAI will not cover accessorial charges.",
      // "11.3 Not repairable:  CAI will pay the claim based on the declared value.",
      // "11.4 Replacement: CAI will not provide a replacement for vintage and antique items. Claims will be based on fair market value. Certified appraisal may be used to determine value.",
    ],
  },
  {
    title: "12.	Damaged furniture: ",
    desc: "A Certified Professional Restoration company will assess damaged items to determine if repairs can be made. Item replacement will only be considered after the repair assessment.",
    points: [
      "12.1. Repairable:  CAI will present the restoration plan to the customer for approval.",
    ],
    subPoints: [
      "12.1.1. Upon approval, CAI will arrange transport to the restoration company and provide detailed images post-restoration CAI will cover All related expenses.",
    ],
    pointsAfter: [
      "12.2. Not repairable:  CAI will pay the claim based on the declared value.",
      "12.3. Replacement: CAI will not provide a replacement for vintage and antique items. Claims will be based on the declared value and or fair market value., whichever is less. A certified appraisal may be used to determine value.",
    ],
  },
  {
    title: "13.	Lost/Stolen Furniture: ",
    desc: 'If the furniture is lost or stolen during transit, CAI will conduct a tracing investigation with the carrier or transporter. If the merchandise cannot be located within 10 business days, CAI will settle the claim based on declared value and/or fair market value, whichever is less. A certified appraisal may be used to determine value.'
    // points: [
    //   "The insured must notify CAI of any damage, theft, or loss within 72 hours, after the scheduled delivery date.",
    // ],
  },
  {
    title: "14.	Notification of Damage, Theft, or Loss: ",
    desc: 'The insured must notify CAI within 72 hours of any damage after delivery has been signed and delivered to the final destination.'
    // points: [
    //   "The insurance company reserves the right to inspect the furniture and the packaging at the point of origin or the final destination to verify the damage.",
    // ],
  },
  {
    title: "15.	Inspection:",
    desc: "CAI reserves the right to inspect insured merchandise and its packaging at the point of origin or the final destination to verify the damage or loss.",
    // points: [
    //   "Damage caused by war, riots, strikes, confiscation by authorities, and any other perils excluded by the insurance company. ",
    //   "Perils: Fire damage, direct and/or indirect loss resulting from catastrophic perils, such as tornadoes, hurricanes, wildfires, windstorms, hail, floods and earthquakes.",
    //   "Cancellation: Exhibit canceled by event coordinator and or perils",
    //   "Bodily injury: Medical expenses, ambulance fees, and lost wages for people injured on-premises during assembly and breakdown and during the event or by your products or services ",
    //   "Property damage: Repairs or replacements for damage to someone else's property ",
    //   "Personal injury: Claims of libel, slander, wrongful eviction, or other personal injuries "
    // ],
    // subPoints: [
    //   "16.1 The policy excludes coverage for used household goods, including those related to household moves.",
    //   "16.2 Merchandise damaged due to delivery vehicle collision. ",
    //   "16.3 Improper packing. See #4.",
    //   // "15.4 Bodily injury: Medical expenses, ambulance fees, and lost wages for people injured on-premises during assembly and breakdown and during the event or by your products or services",
    //   // "15.5 Property damage: Repairs or replacements for damage to someone else's property",
    //   // "15.6 Personal injury: Claims of libel, slander, wrongful eviction, or other personal injuries",
    // ],
  },
  {
    title: "16.	Exclusions: ",
    desc: "The policy is content coverage only. The insurance policy does not cover:",
    points: [
      "16.1.	Damage caused by war, riots, strikes, confiscation by authorities, and any other perils excluded by the insurance company. ",
      "16.2.	Perils: Fire damage, direct and/or indirect loss resulting from catastrophic perils, such as tornadoes, hurricanes, wildfires, windstorms, hail, floods and earthquakes.",
      "16.3.	Cancellation: Exhibit canceled by event coordinator and or perils.",
      "16.4.	Merchandise damaged due to delivery vehicle collision.",
      "16.5.	Bodily injury: Medical expenses, ambulance fees, and lost wages for people injured on-premises during assembly and breakdown and during the event or by your products or services.",
      "16.6.	Property damage: Repairs or replacements for damage to someone else's property.",
      "16.7.	Personal injury: Claims of libel, slander, wrongful eviction, or other personal injuries.",
      "16.8.	Intentional theft or fraud.",
      "16.9.	Excess Value: Reporting more than the declared value above the allowed maximum insurance limits.",
    ],
  },
  {
    title: "17.	Subrogation:",
    desc: 'CAI reserves the right to recover any paid amounts from the responsible parties or carrier.',
    // points: [
    //   "This insurance policy is governed by the laws of the jurisdiction where the shipment originated, and any dispute arising from this insurance policy shall be subject to the exclusive jurisdiction of the courts of that jurisdiction.",
    // ],
  },
  // {
  //   title: "19. Termination",
  //   points: [
  //     "The insurance policy may be terminated by the policyholder or the insurance company for various reasons, including non-payment of premiums or fraudulent claims.",
  //   ],
  // },
  {
    title: "18.	Jurisdiction:",
    desc: "This policy shall be subject to the exclusive jurisdiction of the courts in the jurisdiction where the shipment originated."
  },
  {
    title: "19.	Indemnification:",
    desc: "CAI agrees to indemnify and hold the policyholder harmless against claims, damages, or liabilities arising from the loss or damage of insured items during transit.",
  },
  {
    title: "20.	Policy Period: ",
    desc: "The policy's effective period begins on the date specified at the time of purchase and ends at midnight based on the Plan selected by the exhibitor.",
    points: [
      "20.1.	Policies do not renew automatically. ",
      "20.2.	Additional coverage can be purchased for the Signature Plan only.",
    ],
  },
];

const TradeShowTermsCondition = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    window.open('https://ceasta.com', '_blank', 'noopener,noreferrer');
  };
  const activePlan = useSelector(state => state.tradeShow.plan)
  console.log(activePlan, 'activePlan')
  return (
    <CustomContainer>
      <ClientsPanelHeader />
      <div className='p-[40px] bg-white rounded-xl'>
        <div className="flex items-center gap-2 pb-8 pt-4">
          <button className='border border-[#D0D5DD] text-[#475467] py-[6px] px-3  rounded-lg flex items-center gap-1' onClick={() => navigate(-1)}>
            <CustomIcon icon={backIcon} />
            Back
          </button>
          <CustomHeading className='text-foundation-black font-inter text-[37px] font-[600]'>
            Trade Show Policy Terms and Conditions
          </CustomHeading>
        </div>
        <hr />
        <div className="pt-6">
          {policyData?.map((section, index) => (
            <div key={index} className="mb-6">
              <h2 className="text-2xl font-medium text-[#77553D] mb-2">{section?.title}</h2>
              <p className="text-[#464646] text-lg mb-2">{section?.desc}</p>
              <ul className="list-decimal pl-6 space-y-2">
                {section?.points?.map((point, i) => (
                  <li key={i} className='text-[#464646] text-lg list-none'>{point}</li>
                  // <li key={i} className='text-[#464646] text-lg'>{point}</li>
                ))}
                {section?.subPoints && (
                  <ul className="pl-6 space-y-2">
                    {section.subPoints.map((subPoint, j) => {
                      const isNumbered = /^\d/.test(subPoint); // Check if subPoint starts with a digit
                      return (
                        <li
                          key={j}
                          className={`text-[#464646] flex items-center gap-2 text-lg ${isNumbered ? "" : "pl-12"}`}
                        // dangerouslySetInnerHTML={{ __html: subPoint }}
                        >
                          {!isNumbered && <span className='w-[8px] h-[8px] rounded-full border border-[#77553D] inline-block'></span>}
                          <span>{subPoint}</span>
                        </li>
                      );
                    })}
                  </ul>

                )}
                {section?.pointsAfter?.map((point, i) => (
                  <li key={i} className='text-[#464646] text-lg list-none'>{point}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <p className='text-[#77553D] text-lg font-medium'>All other terms and conditions remain the same per the original Terms and Conditions <span className='font-semibold underline cursor-pointer' onClick={handleClick}>@ceasta.com</span></p>
        {/* <p className='text-[#77553D] text-lg font-medium'>All other terms and conditions remain the same. For further details please refer to <span className='font-semibold underline cursor-pointer' onClick={handleClick}>www.cesta.com</span></p> */}
      </div>
    </CustomContainer>
  )
}

export default TradeShowTermsCondition