import React, { useState } from 'react'
import { usePayment } from './usePayment'
import { Radio } from '@mui/material';
import CustomContainer from '../../../common/custom/CustomContainer';
import { ClientsPanelHeader, CustomButton, CustomHeading } from '../../../common/custom';
import { alertCircleIcon, plusGray } from '../../../assets';
import { PATHS } from '../../../constants/paths';
import { useNavigate } from 'react-router-dom';
import { CustomIcon } from '../../../common/customAssets';

const TradeShowPayment = () => {
    const navigate = useNavigate();
    const { data, selectedCard, loading, setSelectedCard, payTrade } = usePayment();
    console.log(loading, 'payment loading')
    return (
        <CustomContainer>
            <ClientsPanelHeader />
            <div className='pt-4 h-full flex items-center justify-center'>
                <div className='shadow-[0px_1px_9px_0px_#0000001C] bg-white rounded-xl p-[40px] w-full max-w-[895px]'>
                    <div className='flex justify-between items-center gap-2 flex-wrap md:flex-nowrap pb-4'>
                        <CustomHeading className='text-foundation-black font-inter text-xl font-[500] w-full h-full'>
                            Select a Payment Method
                        </CustomHeading>
                        <CustomButton
                            icon={plusGray}
                            text={'Add Payment Method'}
                            onClick={() => navigate(PATHS.TRADESHOWADDPAYMENTMETHOD)}
                            className=' bg-foundation-white  px-[16px]  w-fit flex-shrink-0 rounded-[8px] border  border-[#D0D5DD] h-[40px] flex justify-between items-center text-[14px] font-[500]  gap-3'
                        />
                    </div>
                    <div className='border border-[#FDA29B] rounded-xl p-3 '>
                        <div className='flex items-center gap-3'>
                            <CustomIcon icon={alertCircleIcon} />
                            <span>Your payment will be charged on the date of purchase.</span>
                        </div>
                        <div className='flex items-baseline gap-3 pt-3'>
                            <CustomIcon icon={alertCircleIcon} className='flex-shrink-0' />
                            <span>If cancellation within 24 hours of the exhibit, the premium will be partially refunded. If cancellation. more than 24 hours to exhibit, the premium will be refunded less than 3%.</span>
                        </div>
                    </div>
                    <div className="w-full  pt-6">
                        <div className="space-y-4">
                            {data.map((method, index) => (
                                <div
                                    key={method.id}
                                    onClick={() => setSelectedCard(method.id)} // Handle selection on the card
                                    className={`flex items-center justify-between p-4 border rounded-lg shadow-sm cursor-pointer 
                                        ${selectedCard === method.id
                                            ? "border-[#77553D] bg-[#FFF5ED]" // Selected card styles
                                            : "border-gray-300 bg-white" // Default card styles
                                        }`}
                                >
                                    <div className="flex items-center gap-4">
                                        <Radio
                                            checked={selectedCard === method.id}
                                            onChange={() => setSelectedCard(method.id)}
                                            value={method.id}
                                            name="payment-method"
                                            color="primary"
                                        />
                                        {/* <img
                                            src={`https://via.placeholder.com/40?text=${method.card.brand.toUpperCase()}`}
                                            alt={`${method.card.brand} logo`}
                                            className="h-10 w-10 object-contain"
                                        /> */}
                                        <div>
                                            <p className="text-sm font-medium">
                                                Card {index + 1}
                                            </p>
                                            <p className="text-sm text-gray-500">
                                                ****{method.card.last4}, Exp: {method.card.exp_month}/{method.card.exp_year}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="w-full pt-6 flex justify-end">
                        <button
                            className={`border py-2 px-4 rounded-lg     ${!selectedCard || selectedCard === null
                                ? 'border-gray-300 bg-gray-300 text-gray-500 cursor-not-allowed'
                                : 'border-[#77553D] bg-[#77553D] text-[#FFFFFF]'
                                }`}
                            disabled={loading || !selectedCard || selectedCard === null}
                            onClick={() => {
                                if (!loading && selectedCard) { // Prevent multiple clicks
                                    payTrade();
                                }
                            }}
                        >
                            {loading ? "Processing..." : "Purchase"}
                        </button>
                    </div>
                </div>
            </div>
        </CustomContainer>
    )
}

export default TradeShowPayment