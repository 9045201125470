import { useEffect, useState } from "react";
import { getListPaymentMethodsQuery } from "../../../api";
import useApi from "../../../hook/useApi";
import { storeAllPaymentsAction } from "../../../store/slices/payments";
import { useSelector } from "react-redux";
import { fetchTradeShowDetailsQuery, payTradeShowMutation } from "../../../api/services/tradeShowPolicy.service";
import toast from "react-hot-toast";
import { TOAST_TIME } from "../../../constants/toastNotifications";
import { useLocation, useNavigate } from "react-router-dom";
import { PATHS } from "../../../constants/paths";

export const usePayment = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [selectedCard, setSelectedCard] = useState(null);
    const eventDetails = useSelector(state => state.tradeShow.eventDetails)
    const user = JSON.parse(localStorage.getItem('login-user'));
    const { payments } = useSelector((state) => state);

    const [getListPaymentMethods] = useApi(
        getListPaymentMethodsQuery,
        storeAllPaymentsAction
    );
    const [
        getTradeEventsDetails,
        allTradeEventsLoading,
        allTradeEventsError,
        allTradeEventsData,
    ] = useApi(fetchTradeShowDetailsQuery);

    useEffect(() => {
        if (eventDetails?.id || state) {
            getTradeEventsDetails(parseInt(eventDetails?.id ?? state));
        }
    }, [eventDetails?.id, state]);

    const [payForTradeEvent, payTradeEventLoading, payTradeEventError] = useApi(payTradeShowMutation);

    useEffect(() => {
        getListPaymentMethods(user.location.stripeCustomerID);
    }, []);

    useEffect(() => {
        if (allTradeEventsData?.TradeEventDetails?.isPaid) {
            toast.dismiss();
            toast.error('Trade Show already purchased.', { duration: TOAST_TIME });
            navigate(PATHS.TRADESHOWPOLICY);
        }
    }, [allTradeEventsData?.TradeEventDetails?.isPaid])

    const payTrade = async () => {
        if ((!eventDetails?.id && (!state || state === null)) || selectedCard === null) {
            console.log(state, selectedCard, 'event id  or card not selected')
            return
        };
        const payment = {
            eventId: parseInt(eventDetails?.id ?? state),
            paymentMethodId: selectedCard
        }
        try {
            const { data, errors } = await payForTradeEvent(payment);
            if (data) {
                toast.dismiss();
                toast.success(`Payment done successfully`, {
                    duration: TOAST_TIME,
                });
                navigate(PATHS.TRADESHOWPOLICY);
            } else {
                console.log(errors, 'error')
                toast.dismiss();
                toast.error(`Payment failed`, {
                    duration: TOAST_TIME,
                });
            }
        } catch (error) {
            console.log(error, 'catch error')
        }
    }

    return {
        data: payments?.payments,
        loading: payTradeEventLoading,
        selectedCard,
        setSelectedCard,
        payTrade
    }
}