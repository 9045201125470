import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React, { useState } from 'react';
import * as yup from 'yup';
import { ClientsPanelHeader, CustomInput, CustomText, ValidationErrorText } from '../../../common/custom';
import CustomContainer from '../../../common/custom/CustomContainer';
import CustomDatePicker from '../../../common/custom/CustomDatePicker';
import TextArea from '../../../common/custom/customTextArea';
import { useHookFormSubmission } from '../../../hook/useHookFormSubmission';
import useApi from '../../../hook/useApi';
import { createTradeShowMutation } from '../../../api/services/tradeShowPolicy.service';
import moment from 'moment';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { TOAST_TIME } from '../../../constants/toastNotifications';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../../constants/paths';
import CircularILoader from '../../../common/custom/circularLoader';
import FilePreview from '../../../common/custom/file-preview/file-preview';
import FileUpload from '../../../common/custom/file-upload/file-upload.component';
import { FILE_TYPE, HANDLE_FILE_SIZE_CHECK } from '../../../helpers/helpers';
import axios from 'axios';

const schema = (addTradeShowDetails) => (yup.object().shape({
    eventName: yup.string().required("Event name is required"),
    venue: yup.string().required("Venue/Location is required"),
    startDate: yup
        .string()
        .required("Start date is required")
        .test("valid-date", "Invalid date format", (value) => moment(value, "YYYY-MM-DD", true).isValid())
        .test("future-date", "Select today or a future date.", (value) =>
            moment(value, "YYYY-MM-DD").isSameOrAfter(moment(), "day")
        ),
    endDate: yup
        .string()
        .required("End date is required")
        .test("valid-date", "Invalid date format", (value) => moment(value, "YYYY-MM-DD", true).isValid())
        .test("after-start", "End date should be later than the start date.", (value, context) =>
            moment(value, "YYYY-MM-DD").isAfter(moment(context.parent.startDate, "YYYY-MM-DD"), "day")
        )
        .test("max-difference", function (value) {
            const { startDate } = this.parent;
            const planId = addTradeShowDetails?.plan?.id;

            if (!startDate || !value) return true; // Skip validation if dates are missing

            const start = moment(startDate, "YYYY-MM-DD");
            const end = moment(value, "YYYY-MM-DD");
            const diff = end.diff(start, "days");

            if (planId === "1" && diff > 2) {
                return this.createError({ message: "This plan allows a maximum of 3 days." });
            }
            if (planId === "2" && diff > 9) {
                return this.createError({ message: "This plan allows a maximum of 10 days." });
            }

            return true;
        }),
    additionalNotes: yup
        .string()
        .max(500, "Notes cannot exceed 500 characters")
        .notRequired(),
}));


const AddTradeShowEvents = () => {
    const navigate = useNavigate();
    const [purchaseFiles, setPurchaseFiles] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const addTradeShowDetails = useSelector((state) => state.tradeShow);
    let { handleSubmit, register, errors, trigger, setValue, watch } =
        useHookFormSubmission(schema(addTradeShowDetails));

    const [createEvent] = useApi(createTradeShowMutation);

    const onSubmit = async (values) => {
        console.log(values);
        const createEventInput = {
            name: values?.eventName,
            notes: values?.additionalNotes,
            venue: values?.venue,
            noOfDays: addTradeShowDetails?.plan?.id === '1' ? '1-2' : '3-5', //values.selectDays,
            startDate: moment(values.startDate).format("YYYY-MM-DD"),
            endDate: moment(values.endDate).format("YYYY-MM-DD"),
            subscriptionPlan: { connect: { id: parseInt(addTradeShowDetails?.plan?.id) } },
            // entityMember: { connect: { id: 46 } },
            type: { connect: { id: parseInt(addTradeShowDetails?.activeCategory?.id) } },
            attachments: JSON.stringify([purchaseFiles])
        };

        try {
            const { data, error } = await createEvent(createEventInput);
            if (data) {
                toast.dismiss();
                toast.success(`Event created successfully`, {
                    duration: TOAST_TIME,
                });
                navigate(PATHS.TRADESHOWPOLICYORDERSUMMARY, { state: { id: data?.createTradeEvent?.id, type: "addEvent" } });
            } else {
                toast.dismiss();
                toast.error('Failed to create event...try again later', {
                    duration: TOAST_TIME,
                });
            }
        } catch (error) {
            console.log(error, 'error')
        }
    }

    const handlePurchaseFiles = (files) => {
        try {
            const validFiles = Array.from(files).filter((file) =>
                HANDLE_FILE_SIZE_CHECK(20, file)
            );

            if (validFiles.length === 0) {
                return; // No valid files to upload
            }

            setisLoading(true);
            toast.loading('Files are uploading. Please wait...');

            const uploadPromises = validFiles.map((file) => {
                const formData = new FormData();
                formData.append('upload', file);

                return axios
                    .post(`${process.env.REACT_APP_BACKEND_FILE_UPLOAD_URL}/fileUpload`, formData)
                    .then((response) => response.data)
                    .catch((err) => {
                        console.log('Error in uploading the file: ', err);
                        return null;
                    });
            });

            Promise.all(uploadPromises)
                .then((results) => {
                    const successfulUploads = results.filter((result) => result !== null);
                    setPurchaseFiles((prevFiles) => [...prevFiles, ...successfulUploads]);
                    setisLoading(false);
                    toast.dismiss();
                })
                .catch((err) => {
                    console.log('Error in uploading files: ', err);
                    setisLoading(false);
                    toast.dismiss();
                });
        } catch (err) {
            console.log('Error in uploading the files: ', err);
        }
    };

    const handlePurchaseCancel = (index) => {
        setPurchaseFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };
    return (
        <CustomContainer>
            <ClientsPanelHeader />
            <div className='pt-4 h-full flex items-center justify-center'>
                <div className='shadow-[0px_1px_9px_0px_#0000001C] bg-white rounded-xl p-[40px] w-full max-w-[895px]'>
                    <p className='text-[26px] font-semibold text-[#77553D] mb-2'>Add Event Details</p>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            <CustomInput
                                register={register}
                                name='eventName'
                                placeholder='Enter event name'
                                required
                                type='text'
                                errors={errors}
                                label='Event Name'
                                trigger={trigger}
                                setValue={setValue}
                            />
                        </div>
                        <div className='py-4'>
                            <CustomInput
                                register={register}
                                name='venue'
                                placeholder='Enter venue details (e.g., address'
                                required
                                type='text'
                                errors={errors}
                                label='Venue / Address'
                                trigger={trigger}
                                setValue={setValue}
                            />
                        </div>
                        <div className='flex items-baseline gap-4 flex-wrap md:flex-nowrap'>
                            <div className='shrink-0 flex-grow'>
                                <CustomDatePicker
                                    register={register}
                                    name='startDate'
                                    required
                                    errors={errors}
                                    label='Event Start Date'
                                    trigger={trigger}
                                    setValue={setValue}
                                />
                            </div>
                            <div className='shrink-0 flex-grow'>
                                <CustomDatePicker
                                    register={register}
                                    name='endDate'
                                    required
                                    errors={errors}
                                    label='Event End Date'
                                    trigger={trigger}
                                    setValue={setValue}
                                />
                            </div>
                        </div>
                        {/* <div className='py-4'>
                            <CustomText as='label'
                                className='text-[#464646] text-sm not-italic font-medium leading-[normal]'
                            >
                                Select Days
                            </CustomText>
                            <RadioGroup
                                row
                                aria-label="select-days"
                                value={selectDaysValue} // Bind value to watch
                                onChange={(e) =>
                                    setValue("selectDays", e.target.value, {
                                        shouldValidate: true, // Validate on change
                                    })
                                }
                            >
                                <FormControlLabel
                                    value="1-2"
                                    control={<Radio sx={styles.radio} />}
                                    label="1 - 2 Days"
                                />
                                <FormControlLabel
                                    value="3-5"
                                    control={<Radio sx={styles.radio} />}
                                    label="3 - 5 Days"
                                />
                            </RadioGroup>
                            {errors.selectDays &&
                                <div className='mt-2 mb-2'>
                                    <ValidationErrorText>{errors.selectDays.message}</ValidationErrorText>
                                </div>
                            }
                        </div> */}
                        <div className='pt-4'>
                            <TextArea
                                labelClassName="text-[#464646] text-sm not-italic font-medium leading-[normal]"
                                register={register}
                                name='additionalNotes'
                                placeholder='Add any special instructions or notes (optional)'
                                required
                                errors={errors}
                                label='Additional Notes'
                                trigger={trigger}
                                setValue={setValue}
                            />
                        </div>
                        <div className='pt-4'>
                            <div className='mb-4'>Please upload inventory list and/or photos of exhibit items</div>

                            {purchaseFiles && isLoading ? (
                                <div class='flex h-[126px] justify-center items-center gap-1 self-stretch border px-6 py-4 rounded-xl border-dashed border-[#B8BABE]'>
                                    <CircularILoader />
                                </div>
                            ) : purchaseFiles?.length > 0 ? (
                                <FilePreview
                                    files={purchaseFiles}
                                    handleCancel={handlePurchaseCancel}
                                />
                            ) : (
                                <FileUpload
                                    handleChange={handlePurchaseFiles}
                                    fileTypes={FILE_TYPE}
                                    multiple={true}
                                />
                            )}
                        </div>
                        <div className='flex items-center justify-end gap-6 pt-8'>
                            <button className='border border-[#77553D] text-[#77553D] py-2 px-10  rounded-lg' onClick={() => navigate(-1)}>Cancel</button>
                            <button type="submit" className='border border-[#77553D] bg-[#77553D] text-white py-2 px-8 rounded-lg'>Save Event</button>
                        </div>
                    </form>
                </div>
            </div>
        </CustomContainer>
    )
}

export default AddTradeShowEvents
