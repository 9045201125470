import formPageImg from './img/form-page-img.png';
import ceasataStamp from './img/ceasta-stamp.png';
import ceastaLogo from './svg/ceasta-logo.svg';
import backIcon from './svg/back_icon.svg';
import noClaims from './svg/no_claims.svg';
import noShipments from './svg/no-shipments.svg';
import ceastaLogoWhite from './svg/ceasta_logo_white.svg';
import marker from './svg/marker.svg';
import chevronDown from './svg/chevron-down.svg';
import plusBrown from './svg/plus_brown.svg';
import plusGray from './svg/plus_gray.svg';
import infoDarkIcon from './svg/info-dark.svg';
import userCircle from './svg/user-circle.svg';
import homeIcon from './svg/home_icon.svg';
import divider from './svg/divider_icon.svg';
import unCheckedBoxIcon from './svg/unchecked-box.svg';
import checkedBoxIcon from './svg/checked-box.svg';
import nextIcon from './svg/next_icon.svg';
import actionButton from './svg/action_button.svg';
import deleteIcon from './svg/delete.svg';
import editIcon from './svg/edit.svg';
import cross from './svg/cross.svg';
import crossCircle from './svg/cross-circle.svg';
import claimFile from './svg/claim-file.svg';
import uploadFileIcon from './svg/upload-cloud-02.svg';
import tickIcon from './svg/Tick.svg';
import downArrow from './svg/arror-down.svg';
import conclude from './svg/conclude.svg';
import attachement from './svg/attachement.svg';
import backCircle from './svg/back-circle.svg';
import closeIcon from './svg/close.svg';
import viewIcon from './svg/view.svg';
import informationIcon from './svg/information.svg';
import calendarIcon from './svg/calendar.svg';
import userIcon from './svg/user-account.svg';
import alertCircleIcon from './svg/alert-circle.svg';
import alertCircleBlueIcon from './svg/alert-circle-blue.svg';
import shipmentsMenuIcon from './svg/shipments-icon.svg';
import shipFromMenuIcon from './svg/ship-from-icon.svg';
import staffMenuIcon from './svg/staff-icon.svg';
import paymentsMenuIcon from './svg/payments-icon.svg';
import claimsMenyIcon from './svg/claims-icon.svg';
import billingMenuIcon from './svg/billing.svg';
import filterIcon from './svg/filter.svg';

import paymentVisaIcon from './svg/Payment-visa-icon.svg';
import paymentAmexIcon from './svg/Payment-amex-icon.svg';
import paymentMasterIcon from './svg/Payment-master-icon.svg';

import eye from './svg/eye.svg';
import eyeOff from './svg/eye-off.svg';
import pdfIcon from './svg/pdfIcon.svg';
import wordIcon from './svg/wordIcon.svg';
import logoutBtn from './svg/log-out-btn.svg';

import billingIcon from './svg/billing-icon.svg';
import printIcon from './svg/print-gif.gif';
import download from './svg/download.svg';
import esign from './svg/esign.png';
import arrowUp from './svg/arrow_up_icon.svg';
import arrowDown from './svg/arrow_down_icon.svg';
import dashboard from './svg/dashboard.svg';
import circleDownArrow from './svg/circle-donw-arrow.svg';
import chair from './svg/chair.svg';
import fineArtsStars from './svg/fine-arts-stars.svg';
import interior from './svg/interior.svg';
import bed from './svg/bed.svg';
import approvedIcon from './svg/approved.svg'
import redCross from './svg/redCross.svg'
import resumeIcon from './svg/resume.svg'

export {
  infoDarkIcon,
  resumeIcon,
  chair,
  bed,
  approvedIcon,
  redCross,
  fineArtsStars,
  interior,
  unCheckedBoxIcon,
  checkedBoxIcon,
  ceasataStamp,
  eye,
  arrowUp, circleDownArrow,
  arrowDown,
  dashboard,
  eyeOff,
  paymentVisaIcon,
  paymentAmexIcon,
  paymentMasterIcon,
  claimsMenyIcon,
  paymentsMenuIcon,
  staffMenuIcon,
  shipFromMenuIcon,
  shipmentsMenuIcon,
  billingMenuIcon,
  formPageImg,
  ceastaLogo,
  backIcon,
  noClaims,
  ceastaLogoWhite,
  marker,
  chevronDown,
  plusBrown,
  plusGray,
  userCircle,
  homeIcon,
  divider,
  nextIcon,
  actionButton,
  deleteIcon,
  editIcon,
  cross,
  noShipments,
  claimFile,
  tickIcon,
  uploadFileIcon,
  downArrow,
  alertCircleIcon,
  alertCircleBlueIcon,
  conclude,
  attachement,
  crossCircle,
  backCircle,
  closeIcon,
  viewIcon,
  informationIcon,
  calendarIcon,
  pdfIcon,
  wordIcon,
  logoutBtn,
  billingIcon,
  userIcon,
  filterIcon,
  printIcon,
  download,
  esign,
};
