// persist.config.js
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

const persistConfig = {
  key: 'Root',
  storage,
  whitelist: [
    'ui',
    'forms',
    'shipments',
    'addresses',
    'staff',
    'payments',
    'loginUser',
    "tradeShow"
  ], // Specify reducers to persist
};

export default (reducer) => persistReducer(persistConfig, reducer);
