import axios from 'axios';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { HANDLE_FILE_SIZE_CHECK } from '../../../../../helpers/helpers';

function useUploadDocument(setReleaseFile, setFileError) {
  const [isLoading, setisLoading] = useState(false);

  const handleFileUpload = (file) => {
    const document = HANDLE_FILE_SIZE_CHECK(20, file);
    setisLoading(true);
    toast.loading('Document is uploading! Please wait...');
    if (document) {
      setReleaseFile(file);
      const formData = new FormData();
      formData.append('upload', file);

      axios
        .post(`${process.env.REACT_APP_BACKEND_FILE_UPLOAD_URL}/fileUpload`, formData)
        .then((response) => {
          setReleaseFile([...response.data][0]);
          setFileError(false);
          setisLoading(false);
          toast.dismiss();
        })
        .catch((error) => {
          setisLoading(false);
          toast.dismiss();
        });
    }
  };

  const handleFileCancel = () => {
    setReleaseFile('');
  };

  return {
    handleFileUpload,
    handleFileCancel,
    isLoading,
  };
}

export default useUploadDocument;
