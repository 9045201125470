import { createSlice } from '@reduxjs/toolkit';
import { updateStateProperty } from './tradeShowPolicyActions'

// Initial state with multiple properties
const initialState = {
    activeCategory: null, // Will store an object or remain null initially
    plan: null, // Example property
    eventDetails: null, // Example property
    paymentDetails: null, // Example property
};



const tradeShowSlice = createSlice({
    name: 'tradeShow',
    initialState,
    reducers: {
        storeTradeShowAction: updateStateProperty, // Use the generic reducer
    },
});

export const { storeTradeShowAction } = tradeShowSlice.actions;

export const tradeShowReducer = tradeShowSlice.reducer;
