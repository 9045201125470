import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  getManualClaimDetailsQuery,
  getTradeShowClaimDetailsQuery,
  updateManualClaimMutation,
  updateTradeShowClaimMutation,
} from '../../../api/services/claim.service';
import useApi from '../../../hook/useApi';
import { useHookFormSubmission } from '../../../hook/useHookFormSubmission';
import toast from 'react-hot-toast';
import { TOAST_TIME } from '../../../constants/toastNotifications';
import { PATHS } from '../../../constants/paths';

function UseManualClaimDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [updateClaim] = useApi(updateManualClaimMutation);
  const [updateTradeShowClaim] = useApi(updateTradeShowClaimMutation);

  const { setValue } = useHookFormSubmission();

  const [manualClaimDetails, data] = useApi(getManualClaimDetailsQuery);
  const [tradeShowClaimDetails, tradeShowLoading, tradeShowError, tradeShowData] = useApi(getTradeShowClaimDetailsQuery);

  const [claimType, setClaimType] = useState('');
  const [claimValue, setClaimValue] = useState(0);
  const [claimData, setClaimData] = useState(null);
  const [releaseFile, setReleaseFile] = useState(null);
  const [fileError, setFileError] = useState(false);

  useEffect(() => {
    claimDetail();
  }, [id]);

  const claimDetail = async () => {
    const isTradeShow = pathname?.includes('trade-show');
    const manualClaim = isTradeShow
      ? await tradeShowClaimDetails(Number(id))
      : await manualClaimDetails(Number(id));

    console.log(manualClaim, 'manualClaim');

    // Extract required data
    const claimData = isTradeShow ? manualClaim?.data?.tradeShowClaim : manualClaim?.data?.manualClaim;
    const claimStatus = claimData?.status || '';
    const internalNote = claimData?.internalNote || '';
    const claimValue = manualClaim?.data?.claim?.claimValue || '';

    // Set states
    setClaimData(claimData);
    setClaimType(claimStatus);
    setClaimValue(claimValue);

    // Ensure form values are updated correctly
    setValue('claimType', claimStatus);
    setValue('internalNote', internalNote);
  };

  const options = [
    { id: 'Claim Received', state: 'Claim Received' },
    { id: 'Under Investigation', state: 'Under Investigation' },
    { id: 'Claim Assessed', state: 'Claim Assessed' },
    { id: 'Pending Documentation', state: 'Pending Documentation' },
    { id: 'Claim Approved', state: 'Claim Approved' },
    { id: 'Claim Denied', state: 'Claim Denied' },
    { id: 'Settlement Offered', state: 'Settlement Offered' },
    { id: 'Claim Closed', state: 'Claim Closed' },
    { id: 'Manual Claim Created', state: 'Manual Claim Created' },
  ];

  const handleUpdateClaim = async (values) => {
    // if (!releaseFile) {
    //   setFileError('File is required');
    //   return;
    // }
    toast.loading('Please wait! Updating the claim...', {
      duration: TOAST_TIME,
    });
    const payload = {
      claimId: Number(id),
      // releasefile: JSON.stringify([releaseFile]),
      releasefile: releaseFile
        ? JSON.stringify([releaseFile])
        : claimData?.signedReleaseClaim,
      claimValue: claimValue ? claimValue : claimData?.claimValue,
      bestWayToContact: claimData?.bestWayToContact,
      customerEmail: claimData?.customerEmail,
      customerPhone: claimData?.customerPhone,
      customerNote: claimData?.customerNote,
      description: claimData?.description,
      receipts: claimData?.receipts,
      invoice: claimData?.invoice,
      attachments: claimData?.attachments,
    }
    if (pathname?.includes('trade-show')) {
      const { data, errors } = await updateTradeShowClaim(payload);
      if (data) {
        toast.success('The trade show claim updated successfully!', {
          duration: TOAST_TIME,
        });
        navigate(PATHS.CLAIMS);
      } else if (errors) {
        toast.error('Something went wrong', { duration: TOAST_TIME });
      }
    } else {
      const { data, errors } = await updateClaim(payload);
      if (data) {
        toast.success('The claim updated successfully!', {
          duration: TOAST_TIME,
        });
        navigate(PATHS.CLAIMS);
      } else if (errors) {
        toast.error('Something went wrong', { duration: TOAST_TIME });
      }
    }
  };

  const onCancel = () => navigate(PATHS.CLAIMS);

  return {
    claim: data && data.claim,
    claimType,
    options,
    setClaimType,
    handleUpdateClaim,
    claimData,
    onCancel,
    releaseFile,
    setReleaseFile,
    fileError,
    setFileError,
    claimValue,
    setClaimValue,
  };
}

export default UseManualClaimDetails;
