import { useEffect } from "react";
import { getIndustriesQuery } from "../../../api/services/admin.service";
import useApi from "../../../hook/useApi";

export const useTradeShowCategories = () => {
    const [
        fetchAllIndustries,
        allIndustriesLoading,
        allIndustriesError,
        allIndustriesData,
    ] = useApi(getIndustriesQuery);

    useEffect(() => {
        fetchAllIndustries();
    }, []);

    return {
        allIndustriesData
    }
}