export const CLAIMS = [
  { id: 1, title: 'Recipient', name: 'recipient' },
  { id: 2, title: 'From', name: 'from' },
  { id: 3, title: 'Carrier', name: 'carrier' },
  { id: 4, title: 'Shipment Type', name: 'shipmentType' },
  { id: 11, title: 'Shipment Value', name: 'shipValue' },
  { id: 5, title: 'Claim Value', name: 'value' },
  { id: 6, title: 'Adjusted Amount', name: 'adjusted' },
  { id: 7, title: 'Partial', name: 'partial' },
  { id: 8, title: 'Filed On', name: 'filledOn' },
  { id: 9, title: 'Claim Status', name: 'status' },
  { id: 10, title: 'Action', name: 'action' },
];

export const MANUAL_CLAIMS = [
  { id: 1, title: 'Recipient', name: 'recipient' },
  { id: 5, title: 'Value', name: 'value' },
  { id: 3, title: 'Adjusted Amount', name: 'adjusted' },
  { id: 6, title: 'Filed On', name: 'filledOn' },
  { id: 7, title: 'Claim Status', name: 'status' },
  { id: 8, title: 'Action', name: 'action' },
];

export const SHIPMENTS = [
  { id: 1, title: 'From', name: 'from' },
  { id: 2, title: 'Recipient', name: 'recipient' },
  { id: 9, title: 'Reference', name: 'reference' },
  { id: 3, title: 'Carrier', name: 'carrier' },
  { id: 4, title: 'Pickup', name: 'pickup' },
  { id: 5, title: 'Delivery', name: 'delivery' },
  { id: 6, title: 'Value', name: 'value' },
  { id: 7, title: 'Premium', name: 'premium' },
  { id: 8, title: 'Confirmation', name: 'confirmation' },
  { id: 9, title: 'Trial', name: 'trialed' },
  { id: 10, title: 'Action', name: 'action' },
];

export const STAFF = [
  { id: 1, title: 'Name', name: 'name' },
  { id: 2, title: 'Email', name: 'email' },
  { id: 3, title: 'Role', name: 'role' },
  { id: 4, title: 'Action', name: 'action' },
];

export const SHIP_FROM = [
  { id: 1, title: 'Location Nick Name', name: 'nickName' },
  { id: 2, title: 'Address', name: 'address' },
  { id: 3, title: 'Action', name: 'action' },
];

export const PAYMENT_METHODS = [
  { id: 1, title: 'Payment Method', name: 'paymentMethod' },
  { id: 2, title: 'Type', name: 'type' },
  { id: 3, title: 'Provider', name: 'provider' },
  { id: 4, title: 'Expiration', name: 'expiration' },
  // { id: 5, title: 'Default', name: 'default' },
  { id: 6, title: 'Action', name: 'action' },
];

export const BILLINGS = [
  { id: 1, title: 'Recipient', name: 'recipient' },
  { id: 1, title: 'Reported On', name: 'reported' },
  { id: 2, title: 'Confirmation', name: 'confirmation' },
  { id: 3, title: 'Value', name: 'value' },
  { id: 4, title: 'Premium', name: 'premium' },
  { id: 5, title: 'Status', name: 'status' },
  { id: 6, title: 'Reward', name: 'reward' },
];
export const TRADESHOWPOLICY = [
  { id: 1, title: 'Event Name', name: 'name' },
  { id: 1, title: 'Start Date', name: 'date' },
  { id: 3, title: 'End Date', name: 'deadline' },
  { id: 2, title: 'Venue', name: 'venue' },
  { id: 4, title: 'Premium', name: 'premium' },
  { id: 4, title: 'Paid', name: 'paid' },
  { id: 4, title: 'Plans', name: 'plans' },
  { id: 4, title: 'Status', name: 'status' },
  { id: 4, title: 'Refund', name: 'refund' },
  { id: 5, title: 'Action', name: 'action' },
];

export const ACTIVE_SHIPMENTS = [
  { id: 1, title: 'From', name: 'from' },
  { id: 2, title: 'Recipient', name: 'recipient' },
  { id: 8, title: 'Reference', name: 'reference' },
  { id: 3, title: 'Carrier', name: 'carrier' },
  { id: 4, title: 'Pickup', name: 'pickup' },
  { id: 5, title: 'Delivery', name: 'delivery' },
  { id: 6, title: 'Value', name: 'value' },
  { id: 7, title: 'Premium', name: 'premium' },

  { id: 9, title: 'Confirmation', name: 'confirmation' },
];
