import React from 'react';
import { billingIcon, circleDownArrow, deleteIcon, resumeIcon, viewIcon } from '../../../../assets';
import { CustomText } from '../../../../common/custom';
import { CustomIcon } from '../../../../common/customAssets';
import { PATHS } from '../../../../constants/paths';
import useNavigateHook from '../../../../hook/use-navigate.hook';
import { storeTradeShowAction } from '../../../../store';
import { useDispatch } from 'react-redux';
import { MdOutlineCancel } from "react-icons/md";
import moment from 'moment';

const TradeShowPolicyActionMenu = ({ openDeleteModal, data, upper = false, handleDownload, openCancelModal }) => {
    const { navigate } = useNavigateHook();
    const dispatch = useDispatch();
    console.log(data, 'data trade show')

    const isOlderOrYesterday = (timestamp) => {
        const givenDate = moment.utc(timestamp).format("YYYY-MM-DD"); // Format given date as 'YYYY-MM-DD'
        const currentDate = moment.utc().format("YYYY-MM-DD"); // Get today's date

        // Return true if given date is before today, otherwise false
        return givenDate === currentDate || givenDate < currentDate;
    };
    const cancelStatus = data?.status === 'CANCELED'
    const disableCancelTradeShow = isOlderOrYesterday(data?.startDate) || cancelStatus;
    return (
        <>
            <div
                className={`w-[186px]  min-h-[50px] z-10 shadow-md bg-foundational-white  absolute top-[10px]
                      right-[50px] rounded-[8px] flex flex-col items-center justify-center border-[1px] border-solid border-[#EAECF0] `}
            >
                {data.paid && <div
                    className='cursor-pointer flex items-center w-full min-h-[40px] px-[8px] bg-hover rounded-[6px]'
                    onClick={() => navigate(`${PATHS.TRADESHOWPOLICYEVENTDETAILS}`, { state: { id: data.id } })}
                >
                    {/* <Tooltip className='mt-[-10px] z-50' content='detail'> */}
                    <>
                        {' '}
                        <CustomIcon icon={viewIcon} />
                    </>
                    {/* </Tooltip> */}

                    <CustomText className='font-inter font-[500] ml-[8px] '>
                        View Details
                    </CustomText>
                </div>}
                {data.paid && <div
                    className={`${!cancelStatus ? "cursor-pointer" : "cursor-not-allowed"} flex items-center  w-full   min-h-[40px] px-[8px] bg-hover rounded-[6px]`}
                    onClick={() => { !cancelStatus && handleDownload(data.id) }}
                >
                    <div className={`flex items-center ${cancelStatus && 'opacity-20'}`}>
                        {/* <Tooltip className='mt-[-10px] z-50' content='download'> */}
                        {' '}
                        <CustomIcon icon={circleDownArrow} />
                        {/* </Tooltip> */}
                        <CustomText className="font-inter font-[500] ml-[8px]">Download Doc</CustomText>
                    </div>
                </div>
                }
                {data.paid && <div
                    className={`${(!disableCancelTradeShow) ? "cursor-pointer" : "cursor-not-allowed"} flex items-center  w-full   min-h-[40px] px-[8px] bg-hover rounded-[6px] `}
                    onClick={() => (!disableCancelTradeShow) && openCancelModal()}
                >
                    <div className={`flex items-center ${(disableCancelTradeShow) ? 'opacity-20' : ''}`}>

                        <MdOutlineCancel size={20} className='text-black' />
                        {/* </Tooltip> */}
                        <CustomText className='font-inter font-[500] ml-[8px]'>Cancel</CustomText>
                    </div>
                </div>}
                {/* {data.paid && 
                <div onClick={openDeleteModal}
                    className='cursor-pointer flex items-center w-full min-h-[40px] px-[8px] bg-hover rounded-[6px]'
                >
                    <CustomIcon icon={deleteIcon} />
                    <CustomText className="font-inter font-[500] ml-[8px]">Delete</CustomText>
                </div>
                } */}
                {!data.paid && <div onClick={() => {
                    navigate(`${PATHS.TRADESHOWPAYMENT}`, { state: data.id });
                    dispatch(storeTradeShowAction({ key: 'eventDetails', value: data }))
                }}
                    className='cursor-pointer flex items-center w-full min-h-[40px] px-[8px] bg-hover rounded-[6px]'
                >
                    <CustomIcon icon={resumeIcon} />
                    <CustomText className="font-inter font-[500] ml-[8px]">Resume</CustomText>
                </div>}
            </div>
        </>
    );
};

export default TradeShowPolicyActionMenu;
