import React, { Fragment } from 'react';
import { CustomButton, CustomEmptyDataComponent, CustomHeading } from '../../../common/custom';
import CustomContainer from '../../../common/custom/CustomContainer';
import CustomTable from '../../../common/custom/CustomTable/custom-table.component';
import { TRADESHOWPOLICY } from '../../../constants/columns';
import { DeleteModal } from '../../modals';
import TradeShowPolicyActionMenu from '../components/trade-show-policy-action-menu';
import { useTradeShowListingPolicy } from './useTradeShowListing';
import moment from 'moment';
import TradeShowCategories from '../trade-show-categories';
import { plusGray } from '../../../assets';
import { FORMATE_AMOUNT } from '../../../helpers/helpers';
import { MdOutlineCancel } from 'react-icons/md';

const TradeShowPolicyListing = () => {
    const {
        loading,
        toggleActionMenu,
        actionMenuRef,
        showDeleteModal,
        showCancelModal, closeCancelModal,
        tradeShowActionClickedId,
        tradeShowActionClickedData,
        handleActionClick,
        closeDeleteModal,
        openCancelModal,
        openDeleteModal,
        deleteTradeShowHandler,
        removeTradeShowLoading,
        allTradeEventsData,
        cancelTradeShowHandler,
        handleDownload,
        generatePdfLoading,
        cancelTradeShowLoading,
        isAddTradeShow, setIsAddTradeShow
    } = useTradeShowListingPolicy();

    const tradeEvents = allTradeEventsData?.AllTradeEvents.map(item => ({
        ...item,
        plans: item?.subscriptionPlan.name,
        date: moment(item?.startDate).format('MM/DD/YY'),
        deadline: moment(item?.endDate).format('MM/DD/YY'),
        paid: item?.isPaid,
        premium: FORMATE_AMOUNT(item?.amountPaid),
        refund: FORMATE_AMOUNT(item?.refundAmount)
    }));
    console.log(allTradeEventsData?.AllTradeEvents, 'allTradeEventsData?.AllTradeEvents')
    return (
        <Fragment>
            {showDeleteModal ? (
                <DeleteModal
                    modalHeading='Delete Trade Show?'
                    modalPara={`Are you sure you want to delete this Trade Show Policy?\n This action cannot be undone.`}
                    handleDeleteClick={() => deleteTradeShowHandler(tradeShowActionClickedId)}
                    closeDeleteModal={closeDeleteModal}
                    deleteLoading={removeTradeShowLoading}
                />
            ) : null}
            {showCancelModal ? (
                <DeleteModal
                    modalHeading='Cancel Trade Show?'
                    modalPara={`Are you sure you want to cancel this Trade Show Policy?\n This action cannot be undone.`}
                    modalPoints="If cancellation within 24 hours of the exhibit, the premium will be partially refunded. If cancellation. more than 24 hours to exhibit, the premium will be refunded less than 3%."
                    handleDeleteClick={() => cancelTradeShowHandler(tradeShowActionClickedId)}
                    closeDeleteModal={closeCancelModal}
                    deleteLoading={cancelTradeShowLoading}
                    btnText="Cancel Trade Show"
                    btnClassName="w-fit px-3"
                    modelType="cancel"
                    Icon={MdOutlineCancel}
                />
            ) : null}
            {!isAddTradeShow && <div className=' border-2 border-[#EAECF0] rounded-[7px]'>
                <CustomContainer className='px-6 min-h-[75px] flex justify-between items-center gap-2 flex-wrap md:flex-nowrap'>
                    <CustomHeading className='text-foundation-black font-inter text-small-heading font-[600]   max-w-[1152px] w-full h-full'>
                        Trade Show Property Coverage
                    </CustomHeading>
                    <CustomButton
                        onClick={() => setIsAddTradeShow(prev => !prev)}
                        icon={plusGray}
                        className=' bg-foundation-white  px-[16px]  w-fit flex-shrink-0 rounded-[8px] border  border-[#D0D5DD] h-[40px] flex justify-between items-center text-[14px] font-[500]  gap-3'
                        text={'Add Trade Show'}
                    />
                </CustomContainer>
                <div className=' overflow-x-auto'>
                    {tradeEvents?.length ? (
                        <CustomTable header={TRADESHOWPOLICY} rows={tradeEvents}
                            {...{ handleActionClick, toggleActionMenu }}
                            actionMenuRef={actionMenuRef}
                            menu={
                                <TradeShowPolicyActionMenu
                                    handleDownload={handleDownload}
                                    data={tradeShowActionClickedData}
                                    openDeleteModal={openDeleteModal}
                                    openCancelModal={openCancelModal}
                                    upper={false}
                                />
                            }
                            table='tradeShow' />
                    ) : loading ? (
                        <CustomEmptyDataComponent
                            heading='Loading data...'
                            loading={true}
                        ></CustomEmptyDataComponent>
                    ) : (
                        <CustomContainer >
                            <CustomContainer className='min-h-[620px] flex justify-center items-center  '>
                                <CustomContainer className='max-w-[399px] min-h-[355px]  w-full flex flex-col items-center'>
                                    <CustomHeading className='whitespace-nowrap text-large-heading sm:text-medium-heading md:text-large-heading font-roboto font-[500] text-foundation-black '>
                                        No Data Found!
                                    </CustomHeading>
                                </CustomContainer>
                            </CustomContainer>
                        </CustomContainer>
                    )}{' '}
                </div>
            </div>}
            {isAddTradeShow && <TradeShowCategories />}
        </Fragment>
    )
}

export default TradeShowPolicyListing