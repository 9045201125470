import React from 'react';
import { ClientsPanelHeader } from '../../../common/custom';
import CustomContainer from '../../../common/custom/CustomContainer';
import CustomVerticalStepper from '../../../common/vertical-stepper/vertical-stepper.component';
import RecipientDetails from './component/recipient-detail/receipient-details.component';
import DescribeClaim from './component/describe-claim/describe-claim.component';
import UploadDocument from './component/upload-document/upload-document.component';
import { useCreateClaim } from './use-create-claim.hook';
import { useLocation } from 'react-router-dom';

const CreateClaim = () => {
  const { state } = useLocation();
  const { currentStep, increaseStepHandler, decreaseStepHandler } =
    useCreateClaim();
  const steps = ["Recipient's Detail", 'Describe Claim', 'Upload Document'];
  const displaySteps = (currentStep) => {
    switch (currentStep) {
      case 1:
        return <RecipientDetails increaseStepHandler={increaseStepHandler} type={state} />;
      case 2:
        return (
          <DescribeClaim
            increaseStepHandler={increaseStepHandler}
            decreaseStepHandler={decreaseStepHandler}
          />
        );
      case 3:
        return <UploadDocument decreaseStepHandler={decreaseStepHandler} />;
      default:
    }
  };
  return (
    <CustomContainer>
      <ClientsPanelHeader hideReportButton={true} />
      <div className='flex justify-between'>
        <CustomVerticalStepper steps={steps} currentStep={currentStep} />
        {displaySteps(currentStep)}
      </div>
    </CustomContainer>
  );
};

export default CreateClaim;
