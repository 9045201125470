import React, { useEffect, useState } from 'react';
import {
  AutoCompleteComponent,
  CustomButton,
  CustomDropdown,
  CustomHeading,
  CustomModal,
  CustomText,
} from '../../../../common/custom';
import CustomDropdown2 from '../../../../common/custom/custom-drop-down/index2';
import CustomInput from '../../../../common/custom/CustomInput';
import { useReportShipmentStepOne } from './useReportShipmentStepOne';
import {
  cross,
  informationIcon,
  plusBrown,
  tickIcon,
} from '../../../../assets';
import { CustomIcon } from '../../../../common/customAssets';
import { useLocation } from 'react-router-dom';

const ReportShipmentStepOne = ({ increaseStepHandler, isTrial }) => {
  const { pathname } = useLocation()
  const {
    handleSubmit,
    onSubmit,
    register,
    errors,
    handleGoogleAutoComplete,
    trigger,
    setValue,
    fieldsFromGoogle,
    fromAddresses,
    countries,
    setCountries,
    states = [],
    setStates,
    cities = [],
    setCities,
    setLocation,
    // setSelectedFromAddress,
    setSelectedCountry,
    setSelectedState,
    setSelectedCity,
    selectedFromAddress,
    selectedCountry,
    selectedState,
    selectedCity,
    errFromAddress,
    errCountry,
    errState,
    errCity,
    openTrialModal,
    handleCloseTrialModal,
    handleAddNewClick,
    addressZipCode,
    addressCity,
    addressState,
    addressCountry
  } = useReportShipmentStepOne(increaseStepHandler, isTrial);

  // const handleCloseTrialModal = () => setOpenTrialModal(false);
  console.log(fieldsFromGoogle, 'fieldsFromGoogle')
  console.log(selectedCountry, 'selectedCountry')
  console.log(selectedState, 'selectedState')
  console.log(selectedCity, 'selectedCity')

  return (
    <div className='stepper-cards max-w-[895px] w-full mx-auto p-4 bg-[#F8FAFB] border-[1px] border-[#E7ECF4]'>
      <h1 className='text-[26px] font-semibold mb-8 text-foundation-brown font-inter'>
        Report {pathname.includes('report-test-shipment') ? "Test" : ''} Shipment
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='grid gap-4'>
          <div>
            <CustomDropdown
              label='From Address*'
              placeholder='Select From Address'
              options={fromAddresses}
              error={errFromAddress}
              setValue={setLocation}
              value={selectedFromAddress?.name}
              icon={plusBrown}
              onIconClick={handleAddNewClick}
            />
          </div>
          <div className=''>
            <CustomInput
              register={register}
              name='destination'
              placeholder='Destination(e.g. Recipient or storage facility)*'
              required
              type='text'
              errors={errors}
              label='Destination'
              // asterikColor='#E31836'
              trigger={trigger}
              setValue={setValue}
            />
          </div>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
            <div>
              <CustomInput
                register={register}
                name='reference'
                placeholder='Enter reference#'
                type='text'
                label='Reference'
                trigger={trigger}
                setValue={setValue}
              />
            </div>
            <div>
              <CustomInput
                register={register}
                name='unitNumber'
                placeholder='Enter unit number'
                type='text'
                label='Unit Number'
                trigger={trigger}
                setValue={setValue}
              />
            </div>
          </div>
          <div>
            <AutoCompleteComponent
              setValue={setValue}
              trigger={trigger}
              isFormatted={true}
              errors={errors}
              register={register}
              label='To Address'
              name='streetAddress'
              address={fieldsFromGoogle}
              onChange={handleGoogleAutoComplete}
              required
            />
          </div>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4 hidden'>
            <div className='md:mt-[6px]'>
              {/* <CustomDropdown2
                label='Country*'
                placeholder={'Select Country'}
                options={countries}
                setOptions={setCountries}
                name='country'
                error={errCountry}
                setValue={setSelectedCountry}
                value={selectedCountry}
              /> */}
              <CustomInput
                register={register}
                name='country'
                placeholder='Country name'
                required
                readOnly={true}
                type='text'
                label='Country'
                value={addressCountry}
                errors={errors}
                // asterikColor='#E31836'
                trigger={trigger}
                setValue={setValue}
              />
            </div>
            <div className='md:mt-[6px]'>
              {/* <CustomDropdown2
                label='State*'
                placeholder={'Select State'}
                options={states}
                setOptions={setStates}
                name='state'
                error={errState}
                setValue={setSelectedState}
                value={selectedState}
              /> */}
              <CustomInput
                register={register}
                name='state'
                placeholder='State name'
                required
                readOnly={true}
                type='text'
                label='State'
                value={addressState}
                errors={errors}
                // asterikColor='#E31836'
                trigger={trigger}
                setValue={setValue}
              />
            </div>
          </div>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4 hidden'>
            <div className=''>
              {/* <CustomDropdown2
                label='City*'
                placeholder={'Select City'}
                options={cities}
                setOptions={setCities}
                name='city'
                error={errCity}
                setValue={setSelectedCity}
                value={selectedCity}
              /> */}
              <CustomInput
                register={register}
                name='city'
                placeholder='City name'
                required
                readOnly={true}
                type='text'
                label='City'
                value={addressCity}
                errors={errors}
                // asterikColor='#E31836'
                trigger={trigger}
                setValue={setValue}
              />
            </div>
            <div>
              <CustomInput
                register={register}
                name='zipCode'
                placeholder='Enter your zipcode'
                required
                readOnly={true}
                type='text'
                label='Zip Code'
                value={addressZipCode}
                errors={errors}
                // asterikColor='#E31836'
                trigger={trigger}
                setValue={setValue}
              />
            </div>
          </div>
          <div className='flex justify-end mt-4'>
            <CustomButton className='brown-bg-btn' type='submit' text='Next' />
          </div>
        </div>
      </form>
      {openTrialModal && (
        <CustomModal>
          <div className='max-w-[544px] h-[204px] bg-pure-white w-full rounded-[12px] flex flex-col items-center gap-1 justify-center   '>
            <div className='max-w-[476px] text-[#77553d] w-full h-[72px] gap-1 flex justify-between'>
              <div className=''>
                <div className='flex justify-between'>
                  <CustomHeading className='text-[18px] flex gap-2 font-inter leading-[28px] font-[600] mb-1 min-w-[200px]'>
                    <CustomIcon
                      icon={informationIcon}
                      className=' h-[24px] w-[24px]'
                    />
                    Trial Shipment
                  </CustomHeading>
                  <div
                    onClick={() => handleCloseTrialModal()}
                    className=' cursor-pointer h-[24px] w-full flex justify-end max-w-[476px]'
                  >
                    <CustomIcon
                      icon={cross}
                      className=' h-[12px] w-[12px] flex justify-center items-center'
                    />
                  </div>
                </div>
                <CustomText>
                  This is your first shipment, and it will be considered as
                  trial i.e. no premium will be charged for your first shipment
                  upto total value of $5000.
                  <br />
                  {/* <div className='w-full flex justify-between gap-2'>
                    <CustomIcon
                      icon={tickIcon}
                      className='h-[16px] w-[16px] flex justify-center items-center bg-[#77553d] rounded rounded-full p-1'
                    />
                    Trial shipment will not be charged for total value amounting
                    less than or equal to $5000.
                  </div> */}
                </CustomText>
              </div>
            </div>
            <div className=' h-[44px] flex justify-end gap-[12px] w-full max-w-[476px] mt-[36px] '>
              <CustomButton
                onClick={handleCloseTrialModal}
                type='button'
                text='Confirm'
                className='w-[87px] h-[44px] bg-[#77553d] rounded-[8px] text-pure-white'
              />
            </div>
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default ReportShipmentStepOne;
