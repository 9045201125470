import React from 'react'
import TradeShowPolicy from '../../features/trade-show-policy'

const TradeShowPolicyPage = () => {
    return (
        <TradeShowPolicy />
    )

}

export default TradeShowPolicyPage