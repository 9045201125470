import { useEffect, useRef, useState } from "react";
import useOutsideClick from "../../../hook/useOutsideClick";
import useApi from "../../../hook/useApi";
import { cancelTradeShowMutation, fetchAllTradeEvents, generatePdfQuery, removeTradeShowMutation } from "../../../api/services/tradeShowPolicy.service";
import { TOAST_TIME } from "../../../constants/toastNotifications";
import toast from "react-hot-toast";

export const useTradeShowListingPolicy = () => {
    const actionMenuRef = useRef(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [isAddTradeShow, setIsAddTradeShow] = useState(false);
    const [tradeShowActionClickedData, setTradeShowActionClickedData] = useState(null);
    const [tradeShowActionClickedId, setTradeShowActionClickedId] = useState(null);
    const [toggleActionMenu, setToggleActionMenu] = useState(null);

    useOutsideClick(actionMenuRef, () => setToggleActionMenu(false));

    const [
        getAllTradeEvents,
        allTradeEventsLoading,
        allTradeEventsError,
        allTradeEventsData,
    ] = useApi(fetchAllTradeEvents);

    const [removeTradeShowCall, removeTradeShowLoading] = useApi(removeTradeShowMutation);
    const [cancelTradeShowCall, cancelTradeShowLoading] = useApi(cancelTradeShowMutation);
    const [generatePdfCall, generatePdfLoading] = useApi(generatePdfQuery);

    useEffect(() => {
        getAllTradeEvents();
    }, []);

    const handleActionClick = (e, data) => {
        e.stopPropagation();
        setTradeShowActionClickedId(parseInt(data?.id));
        setTradeShowActionClickedData(data);

        toggleActionMenu ? setToggleActionMenu(null) : setToggleActionMenu(data.id);
        data && localStorage.setItem('shipment', JSON.stringify(data));
    };

    const openDeleteModal = () => setShowDeleteModal(true);
    const closeDeleteModal = () => setShowDeleteModal(false);
    const openCancelModal = () => setShowCancelModal(true);
    const closeCancelModal = () => setShowCancelModal(false);

    const cancelTradeShowHandler = async (tradeShowId) => {
        console.log(tradeShowId, 'tradeShowId')
        const { data, error } = await cancelTradeShowCall(parseInt(tradeShowId));
        if (data) {
            toast.dismiss();
            toast.success('Trade show cancelled', { duration: TOAST_TIME });
            getAllTradeEvents();
            closeCancelModal()
        } else {
            toast.dismiss();
            toast.error('Failed to cancel trade show.', { duration: TOAST_TIME });
        }
    }
    const deleteTradeShowHandler = async (tradeShowId) => {
        console.log(tradeShowId, 'tradeShowId')
        const { data, error } = await removeTradeShowCall(parseInt(tradeShowId));
        if (data) {
            toast.dismiss();
            toast.success('Trade show removed', { duration: TOAST_TIME });
            getAllTradeEvents();
            closeDeleteModal()
        } else {
            toast.dismiss();
            toast.error('Failed to remove trade show.', { duration: TOAST_TIME });
        }
    }

    const handleDownload = async (tradeId) => {
        const { data, error } = await generatePdfCall(parseInt(tradeId));
        if (data?.TradeEventInvoice?.url && data?.TradeEventInvoice?.url !== undefined && data?.TradeEventInvoice?.url !== '' && data?.TradeEventInvoice?.url !== null) {
            const pdfUrl = data?.TradeEventInvoice.url;
            toast.loading('Trade Event PDF downloading...', { duration: TOAST_TIME });
            // Open the PDF in a new tab
            window.open(pdfUrl, '_blank');
            console.log('PDF opened in a new tab:', pdfUrl);
        } else {
            toast.dismiss();
            toast.error('Failed to download trade show.', { duration: TOAST_TIME });
        }
    }

    return {
        loading: allTradeEventsLoading || removeTradeShowLoading || generatePdfLoading || cancelTradeShowLoading,
        tradeShowActionClickedId,
        tradeShowActionClickedData,
        showDeleteModal,
        toggleActionMenu,
        actionMenuRef,
        handleActionClick,
        closeDeleteModal,
        generatePdfLoading,
        handleDownload,
        openDeleteModal,
        openCancelModal,
        deleteTradeShowHandler,
        cancelTradeShowHandler,
        allTradeEventsData,
        removeTradeShowLoading, showCancelModal, closeCancelModal,cancelTradeShowLoading,
        isAddTradeShow, setIsAddTradeShow
    }
}