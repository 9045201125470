import React from 'react';
import { ClientsPanelHeader } from '../../common/custom';
import CustomContainer from '../../common/custom/CustomContainer';
import TradeShowPolicyListing from './trade-show-policy-listing';

const TradeShowPolicy = () => {


    return (
        <CustomContainer>
            <ClientsPanelHeader />
            <TradeShowPolicyListing />
        </CustomContainer>
    )
}

export default TradeShowPolicy