import { useState } from "react"
import { useTradeShowDetails } from "./useTradeShowDetails"
import CustomContainer from "../../../../common/custom/CustomContainer"
import { ClientsPanelHeader, CustomHeading } from "../../../../common/custom"
import moment from "moment"
import { PATHS } from "../../../../constants/paths"
import { useDispatch } from "react-redux"
import { storeTradeShowAction } from "../../../../store"
import { FaCalendarAlt, FaBriefcase, FaUser, FaBuilding, FaCreditCard, FaShieldAlt } from "react-icons/fa"
import { FORMATE_AMOUNT } from "../../../../helpers/helpers"

const Tooltip = ({ children, content }) => {
    const [isVisible, setIsVisible] = useState(false)

    return (
        <div className="relative inline-block">
            <div onMouseEnter={() => setIsVisible(true)} onMouseLeave={() => setIsVisible(false)}>
                {children}
            </div>
            {isVisible && (
                <div className="absolute z-10 w-64 p-2 mt-2 text-sm text-white bg-gray-800 rounded-lg shadow-lg">{content}</div>
            )}
        </div>
    )
}

const InfoSection = ({ title, data, icon: Icon }) => (
    <div className="bg-white rounded-lg shadow-md overflow-hidden mb-6">
        <div className="bg-gradient-to-r from-[#77553D] to-[#C2A287] p-4 flex items-center">
            <div className="bg-white p-2 rounded-full mr-3">
                <Icon className="text-[#77553D] text-xl" />
            </div>
            <h2 className="text-white text-lg font-semibold">{title}</h2>
        </div>
        <div className="p-4">
            {data.map((item, index) => (
                <div key={index} className="flex justify-between py-2 border-b border-gray-200 last:border-b-0">
                    <span className={`text-[#6B7280] ${item?.largeText ? "text-xl" : 'text-base'} font-medium`}>{item.label}</span>
                    {item.value && item.value.length > 60 ? (
                        <Tooltip content={item.value}>
                            <span className="text-[#1F2937] text-base font-semibold truncate max-w-[150px]">
                                {item.value.substring(0, 60)}...
                            </span>
                        </Tooltip>
                    ) : (
                        <span className={`text-[#1F2937] ${item?.largeText ? "text-xl" : 'text-base'} font-semibold`}>{item.value || "-"}</span>
                    )}
                </div>
            ))}
        </div>
    </div>
)

const TradeShowDetails = () => {
    const dispatch = useDispatch()
    const { data, navigate, state } = useTradeShowDetails()

    console.log(data, 'data');
    const start = moment(data?.startDate, "YYYY-MM-DD");
    const end = moment(data?.endDate, "YYYY-MM-DD");
    const diff = end.diff(start, "days") + 1;

    const formatEventDates = (startDate, endDate) => {
        const start = moment(startDate)
        const end = moment(endDate)

        if (start.isSame(end, "month") && start.isSame(end, "year")) {
            return `${start.format("MMMM D")}–${end.format("D, YYYY")}`
        } else if (start.isSame(end, "year")) {
            return `${start.format("MMMM D")}–${end.format("MMMM D, YYYY")}`
        } else {
            return `${start.format("MMMM D, YYYY")}–${end.format("MMMM D, YYYY")}`
        }
    }

    return (
        <CustomContainer className="bg-gray-50 min-h-screen py-8">
            <ClientsPanelHeader />
            {state?.type === "addEvent" && (
                <CustomHeading className="text-[#1F2937] font-inter text-center text-3xl font-bold w-full mb-8">
                    Order Summary
                </CustomHeading>
            )}
            <CustomContainer className="max-w-6xl w-full mx-auto px-4 sm:px-6 lg:px-8 pb-12">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <InfoSection
                        title={state?.type === "addEvent" ? "Event Details" : "Trade Show Details"}
                        icon={FaCalendarAlt}
                        data={[
                            { label: "Event Name", value: data?.name },
                            { label: "Event Date", value: formatEventDates(data?.startDate, data?.endDate) },
                            { label: "Venue Address", value: data?.venue },
                        ]}
                    />
                    <InfoSection
                        title="Policy Details"
                        icon={FaBriefcase}
                        data={[
                            { label: "Subscription Plan", value: data?.subscriptionPlan?.name },
                            { label: "Type", value: data?.type?.name },
                        ]}
                    />
                    <InfoSection
                        title="Effective Dates"
                        icon={FaCalendarAlt}
                        data={[
                            { label: "Start Date", value: moment(data?.startDate).format("MM/DD/YYYY") },
                            { label: "End Date", value: moment(data?.endDate).format("MM/DD/YYYY") },
                        ]}
                    />
                    <InfoSection
                        title="Policy Owner Details"
                        icon={FaUser}
                        data={[
                            { label: "First Name", value: data?.entityMember?.firstName },
                            { label: "Last Name", value: data?.entityMember?.lastName },
                            { label: "Email", value: data?.entityMember?.emailAddress },
                            { label: "Phone Number", value: data?.entityMember?.phoneNumber },
                        ]}
                    />
                </div>
                <div className="mt-6">
                    <InfoSection
                        title="Limit of Insurance"
                        icon={FaShieldAlt}
                        data={[
                            { label: "Maximum coverage", value: FORMATE_AMOUNT(data?.subscriptionPlan?.details?.coverageAmount) },
                            { label: "Maximum single Item Limit", value: FORMATE_AMOUNT(data?.subscriptionPlan?.details?.singleItemLimit) },
                            { label: "Deductible", value: FORMATE_AMOUNT(0) },
                            { label: "Total Premium", value: FORMATE_AMOUNT(data?.subscriptionPlan?.price) },
                            { label: "Extra days fee", value: FORMATE_AMOUNT(diff > 7 ? (diff - 7) * 100 : 0) },
                            { label: "Card processing fee", value: FORMATE_AMOUNT((data?.subscriptionPlan?.price + (diff > 7 ? (diff - 7) * 100 : 0)) * (3 / 100)) },
                            { label: "Total Amount to be paid", value: FORMATE_AMOUNT(data?.subscriptionPlan?.price + (data?.subscriptionPlan?.price + (diff > 7 ? (diff - 7) * 100 : 0)) * (3 / 100) + (diff > 7 ? (diff - 7) * 100 : 0)), largeText: true },
                        ]}
                    />
                </div>
                <div className="mt-6">
                    <InfoSection
                        title="Business Information"
                        icon={FaBuilding}
                        data={[
                            { label: "Business Name", value: data?.entityMember?.entity?.name },
                            { label: "Business Address", value: data?.entityMember?.entity?.address },
                            { label: "EIN", value: data?.entityMember?.entity?.EIN },
                            { label: "Industry", value: data?.entityMember?.entity?.type?.name },
                            { label: "Principal", value: data?.entityMember?.entity?.principalName },
                        ]}
                    />
                </div>

                <div className="flex items-center justify-center pt-6 gap-6">
                    <button
                        className="bg-white border border-[#D1D5DB] text-[#374151] py-3 px-6 rounded-lg font-medium hover:bg-gray-50 transition-colors duration-200"
                        onClick={() => navigate(-1)}
                    >
                        Go Back
                    </button>
                    {state.type === "addEvent" && (
                        <button
                            className="bg-[#77553D] text-white py-3 px-6 rounded-lg font-medium hover:bg-[#5F4431] transition-colors duration-200 flex items-center"
                            onClick={() => {
                                navigate(PATHS.TRADESHOWPAYMENT, { state: state?.id })
                                dispatch(storeTradeShowAction({ key: "eventDetails", value: data }))
                            }}
                        >
                            <FaCreditCard className="mr-2" />
                            Proceed to Checkout
                        </button>
                    )}
                </div>
            </CustomContainer>
        </CustomContainer>
    )
}

export default TradeShowDetails

