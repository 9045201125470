import React from 'react'
import { CustomHeading } from '../../../../../common/custom'
import { CustomIcon } from '../../../../../common/customAssets';
import { alertCircleIcon } from '../../../../../assets';
import BackButton from '../../../../../common/custom/BackButton';
import CustomContainer from '../../../../../common/custom/CustomContainer';

const insuranceCoverageDetails = [
    {
        title: "General Aggregate (Other than Products-Completed)",
        value: "$2,000,000",
    },
    {
        title: "Products Completed Operations Aggregate",
        value: "$2,000,000",
    },
    {
        title: "Personal & Advertising Injury",
        value: "$1,000,000",
    },
    {
        title: "General Each Occurrence",
        value: "$1,000,000",
    },
    {
        title: "Damage to Premises Rented to You Limit (any one premises)",
        value: "No Deductible: $300,000",
    },
    {
        title: "Business Personal Property Limit (max payout for any one claim)",
        value: "$5,000 or $10,000 (depending on policy selection)",
    },
    {
        title: "Annual Aggregate Property (Described in your policy, or under your care, custody, or control)",
        value: "$10,000 or $50,000 (depending on policy selection)",
    },
    {
        title: "Business Personal Property (Deductible per occurrence)",
        value: "$250 Deductible",
    },
];

const coverageDetailsData = {
    inclusions: [
        // "Covers loss or damage to customer’s exhibit items due to but not limited to:",
        "Theft, vandalism, and damage of business property during trade show",
        "Installation, breakdown, and packing.",
        "Property is in transit to/from the trade show or to the final destination.",
        // "Coverage shall extend up to 24 hours after break-down and transit to the final destination.",
        "Coverage shall be reported online under the standard premium policy if intermediate storage is needed. Refer to Ceasta.com or contact support@ceasta.com for approval.",
        "Coverage includes marble, glass, and mirrors w/frames."
    ],
    exclusions: [
        "Fire damage, direct and/or indirect loss resulting from catastrophic perils, such as tornadoes, hurricanes, wildfires, windstorms, hail, floods, and earthquakes.",
        "Exhibit cancellation/disruption by event coordinator or perils.",
        "Third-Party bodily injury",
        "Advertising injury",
        "Rental equipment, furniture, or accessories"
    ]
};


const CoverageDetails = ({ coverageDetails, setCoverageDetails }) => {
    const formattedNumber = (number) => (new Intl.NumberFormat('en-US').format(number));
    // Clone inclusions and exclusions to modify based on coverageDetails.id
    let updatedInclusions = [...coverageDetailsData.inclusions];
    let updatedExclusions = [...coverageDetailsData.exclusions];

    if (coverageDetails?.id === '1') {
        // Remove the third item from inclusions
        updatedInclusions.splice(2, 1);
        // Add new exclusion at index 2
        updatedExclusions.splice(2, 0, "Coverage does not extend to property in transit to/from exhibit");
    }

    return (
        <CustomContainer className='max-w-6xl w-full mx-auto px-4 sm:px-6 lg:px-8 pb-12'>
            <CustomHeading className='text-foundation-black font-inter text-small-heading font-[600] pb-4 pt-4 text-center w-full h-full'>
                Coverage Details
            </CustomHeading>
            <p className='text-[#475467] text-2xl font-medium pb-3'>Limits Of Insurance</p>
            <div className='flex pb-3 items-center justify-between ga-2 flex-wrap md:flex-nowrap'>
                <p className='text-lg text-[#464646]'>Limit value per single item</p>
                <p className='text-lg text-[#464646] font-semibold'>${formattedNumber(coverageDetails?.details?.singleItemLimit)}</p>
            </div>
            <div className='flex pb-3 items-center justify-between ga-2 flex-wrap md:flex-nowrap'>
                <p className='text-lg text-[#464646]'>Coverage for the exhibited items</p>
                <p className='text-lg text-[#464646] font-semibold'>${formattedNumber(coverageDetails?.details?.coverageAmount)}</p>
            </div>
            <div className='flex pb-3 items-center justify-between ga-2 flex-wrap md:flex-nowrap'>
                <p className='text-lg text-[#464646]'>Days Limit</p>
                <p className='text-lg text-[#464646] font-semibold'>{coverageDetails?.id == '1' ? '1-3 Days' : "1-7 days and up to 10days $100 per diem"}</p>
            </div>
            <p className='text-lg text-[#464646]'>No deductible</p>
            {/* {insuranceCoverageDetails.map((item, i) =>
                <div key={i} className='flex pb-3 items-center justify-between ga-2 flex-wrap md:flex-nowrap'>
                    <p className='text-lg text-[#464646]'>{item.title}</p>
                    <p className='text-lg text-[#464646] font-semibold'>{item.value}</p>
                </div>
            )} */}
            <div className='my-6 border-b border-[#E6E6E6]' />
            <p className='text-[#464646] text-2xl font-medium pb-2'>Inclusions</p>
            {/* <div className='flex item-center gap-3 border border-[#FDA29B] rounded-xl p-3'>
                <CustomIcon icon={alertCircleIcon} />
                <span>To qualify, at least 80% of the products you sell must be handmade or handcrafted by you.</span>
            </div> */}
            <ul className='pt-4' style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                {updatedInclusions?.map((item, i) =>
                    <li key={i} className='text-[#667085] text-base font-inter pb-4'>{item}</li>
                )}
            </ul>
            <p className='text-[#D92D20] text-2xl font-medium pb-2'>Exclusions</p>
            {/* <div className='flex item-center gap-3 border border-[#FDA29B] rounded-xl p-3'>
                <CustomIcon icon={alertCircleIcon} />
                <span>To qualify, at least 80% of the products you sell must be handmade or handcrafted by you.</span>
            </div> */}
            <ul className='pt-4' style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                {updatedExclusions?.map((item, i) =>
                    <li key={i} className='text-[#667085] text-base font-inter pb-4'>{item}</li>
                )}
            </ul>
            <div className='flex item-center justify-center pt-6'>
                <button className='border border-[#D0D5DD] text-[#475467] py-2 px-4  rounded-lg' onClick={() => setCoverageDetails(null)}>Go Back</button>
            </div>
        </CustomContainer>
    )
}

export default CoverageDetails