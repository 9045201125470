import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHookFormSubmission } from '../../../../hook/useHookFormSubmission';
import {
  setAddressAction,
  updateReportShipmentFormAction,
} from '../../../../store';

import { reportShipmentStepOneSchema } from '../../schema/reportShipmentStepOneSchema';
import toast from 'react-hot-toast';
import {
  fetchCities,
  fetchCountries,
  fetchStates,
  getMyLocationsAddressesQuery,
} from '../../../../api/services/address.service';
import useApi from '../../../../hook/useApi';
import { PARSIFY } from '../../../../helpers/helpers';
import { TOAST_TIME } from '../../../../constants/toastNotifications';
import { useLocation, useNavigate } from 'react-router-dom';
import { PATHS } from '../../../../constants/paths';

export const useReportShipmentStepOne = (increaseStepHandler, isTrial) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation()
  const testReportShipment = pathname.includes('report-test-shipment')

  const { addresses } = useSelector((state) => state);
  const user = JSON.parse(localStorage.getItem('login-user'));

  const [fieldsFromGoogle, setFieldsFromGoogle] = useState(null);
  const { reportShipment } = useSelector((state) => state.forms);

  let { handleSubmit, register, errors, trigger, setValue, watch } =
    useHookFormSubmission(reportShipmentStepOneSchema);

  const [fromAddresses, setFromAddresses] = useState([]);

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [selectedFromAddress, setSelectedFromAddress] = useState(null);

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const [addressCountry, setAddressCountry] = useState(null);
  const [addressState, setAddressState] = useState(null);
  const [addressCity, setAddressCity] = useState(null);
  const [addressZipCode, setAddressZipCode] = useState(null);

  const [errFromAddress, setErrFromAddress] = useState(null);

  const [errCountry, setErrCountry] = useState(null);
  const [errState, setErrState] = useState(null);
  const [errCity, setErrCity] = useState(null);

  const countryValue = watch('country');
  const stateValue = watch('state');
  const cityValue = watch('city');

  const [openTrialModal, setOpenTrialModal] = useState(isTrial);

  const [getMyLocationsAddressesCall] = useApi(getMyLocationsAddressesQuery);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let _countries = await fetchCountries();

        _countries = _countries.map((item) => ({
          label: item.country,
          value: item.country,
        }));

        setCountries(_countries);
      } catch (error) {
        // console.error('Error fetching countries:', error);
      }
    };

    fetchData();
    fetchAddresses();

    setValue(
      'country',
      reportShipment.formData.step1?.country
        ? reportShipment.formData.step1.country
        : ''
    );
    setValue(
      'destination',
      reportShipment.formData.step1?.destination
        ? reportShipment.formData.step1.destination
        : ''
    );
    setValue(
      'reference',
      reportShipment.formData.step1?.reference
        ? reportShipment.formData.step1.reference
        : ''
    );
    setValue(
      'unitNumber',
      reportShipment.formData.step1?.unitNumber
        ? reportShipment.formData.step1.unitNumber
        : ''
    );
    setValue(
      'streetAddress',
      reportShipment.formData.step1?.streetAddress
        ? reportShipment.formData.step1.streetAddress
        : ''
    );
    setFieldsFromGoogle(
      reportShipment.formData.step1?.streetAddress
        ? reportShipment.formData.step1.streetAddress
        : ''
    );
    // setValue(
    //   'city',
    //   reportShipment.formData.step1?.city
    //     ? reportShipment.formData.step1.city
    //     : ''
    // );
    setValue(
      'zipCode',
      reportShipment.formData.step1?.zipCode
        ? reportShipment.formData.step1.zipCode
        : ''
    );
    // setValue(
    //   'state',
    //   reportShipment.formData.step1?.state
    //     ? reportShipment.formData.step1.state
    //     : ''
    // );
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let _states = await fetchStates(
          selectedCountry ? selectedCountry : countryValue
        );
        _states = _states.map((item) => ({
          label: item.name,
          value: item.name,
        }));
        setStates(_states);
        setSelectedState(null);
        setSelectedCity(null);
      } catch (error) {
        // console.error('Error fetching countries:', error);
      }
    };

    fetchData();
  }, [selectedCountry]);

  useEffect(() => {
    if (!testReportShipment) {
      setOpenTrialModal(isTrial);
    }
  }, [isTrial]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let _cities = await fetchCities(
          selectedCountry ? selectedCountry : countryValue,
          selectedState ? selectedState : stateValue
        );
        _cities = _cities.map((item) => ({
          label: item.name,
          value: item.name,
        }));
        setCities(_cities);
      } catch (error) {
        // console.error('Error fetching cities:', error);
      }
    };

    fetchData();
    setSelectedCity();
    // setTimeout(() => {
    setValue(
      'city',
      selectedCity ? selectedCity : reportShipment.formData.step1?.city
    );
    // console.log('city:::', selectedCity);
    // }, 1000);
  }, [
    useMemo(
      () => [selectedCountry, countryValue, selectedState, stateValue],
      [selectedCountry, countryValue, selectedState, stateValue]
    ),
  ]);

  const fetchAddresses = async () => {
    try {
      if (user) {
        let response = await getMyLocationsAddressesCall(user?.locationId);
        const _addresses = response?.data?.locationAddresses.map((item) => {
          const address = PARSIFY(item?.address);
          return {
            ...item,
            id: Number(item.id),
            name: `${item.nickName} ( ${address?.street}, ${address?.city}, ${address?.state}, ${address?.country} ${address?.zipCode} )`,
          };
        });

        setFromAddresses(_addresses);
        setSelectedFromAddress(
          addresses?.selectedAddress
            ? addresses?.selectedAddress
            : _addresses[0]
        );
        dispatch(
          setAddressAction(
            addresses?.selectedAddress
              ? addresses?.selectedAddress
              : _addresses[0]
          )
        );
      }
    } catch (error) {
      // console.error('Error fetching addresses:', error);
    }
  };

  const setLocation = (location) => {
    if (
      location &&
      location?.name &&
      selectedFromAddress?.name !== location?.name
    ) {
      dispatch(setAddressAction(location));
      toast.dismiss();
      toast.success(`${location?.name?.split('(')[0]} selected successfully`, {
        duration: TOAST_TIME,
      });
      setSelectedFromAddress(location);
    }
  };

  const onSubmit = (state) => {
    if (selectedFromAddress === null) {
      setErrFromAddress('From address is required ');
      return;
    }

    // if (selectedCountry == '' || selectedCountry == null) {
    //   setErrCountry('Country is required ');
    //   return;
    // }
    // if (selectedState == '' || selectedState == null) {
    //   setErrState('State is required ');
    //   return;
    // }
    // if (selectedCity == '' || selectedCity == null) {
    //   setErrCity('City is required ');
    //   return;
    // }

    const values = {
      ...state,
      streetAddress: fieldsFromGoogle,
      country: addressCountry,
      state: addressState,
      city: addressCity,
      zipCode: addressZipCode,
      selectedFromAddress: selectedFromAddress,
    };
    // console.log('values:', values);
    toast.dismiss();
    increaseStepHandler();
    dispatch(
      updateReportShipmentFormAction({
        step: 1,
        values,
      })
    );
  };

  const handleGoogleAutoComplete = (newAddress, address) => {
    console.log(newAddress, 'newAddress')
    setFieldsFromGoogle(newAddress);
    setAddressCountry(address?.country)
    setAddressState(address?.state)
    setAddressCity(address?.city)
    setAddressZipCode(address?.zipCode)
  };

  const handleCloseTrialModal = () => setOpenTrialModal(false);

  const handleAddNewClick = () => navigate(PATHS.ADD_SHIP_FROM_ADDRESS);

  return {
    handleSubmit,
    onSubmit,
    register,
    errors,
    handleGoogleAutoComplete,
    trigger,
    setValue,
    fieldsFromGoogle,
    fromAddresses,
    countries,
    setCountries,
    states,
    setStates,
    cities,
    setCities,
    setLocation,
    // setSelectedFromAddress,
    setSelectedCountry,
    setSelectedState,
    setSelectedCity,
    selectedFromAddress,
    selectedCountry,
    selectedState,
    selectedCity,
    errFromAddress,
    errCountry,
    errState,
    errCity,
    openTrialModal,
    handleCloseTrialModal,
    handleAddNewClick,
    addressZipCode,
    addressCity,
    addressState,
    addressCountry
  };
};
