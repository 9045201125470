import React from 'react';
import {
  Box,
  Button,
  Card,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import ReactECharts from 'echarts-for-react';
import CustomContainer from '../../common/custom/CustomContainer';
import { dashboardStyles } from './dasboard.styles';
import { arrowDown, arrowUp, plusGray } from '../../assets';
import { useDashboard } from './useDashboard';
import CircularILoader from '../../common/custom/circularLoader';
import { FORMATE_AMOUNT } from '../../helpers/helpers';
import { CustomButton } from '../../common/custom';

// Styled component to apply custom styles to the dropdown
const StyledMenuProps = {
  PaperProps: {
    sx: {
      maxHeight: '300px',
    },
  },
};

const Dashboard = () => {
  const {
    data,
    overviewTab,
    overviewChartOptions,
    ShipmentChartOptions,
    handleOverviewTab,
    selectedMonth,
    monthlyFilter,
    yearsFilterArray,
    selectedYear,
    handleYearChange,
    reportShipmentHandler,
    handleMonthChange,
    loading,
  } = useDashboard();
  const userDetails = localStorage.getItem('login-user');
  const parsedData = JSON.parse(userDetails);
  return (
    <>
      {loading ? (
        <div class='flex h-[326px] justify-center items-center gap-1 self-stretch px-6 py-4 rounded-xl'>
          <CircularILoader />
        </div>
      ) : (
        <CustomContainer>
          <Box sx={{ pt: { md: '40px', xs: '20px' } }}>
            <Card sx={dashboardStyles.card}>
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItem: 'center',
                    gap: '20px',
                    flexWrap: 'wrap',
                  }}
                >
                  <Typography sx={dashboardStyles.welcomeText}>
                    {`Welcome back, ${parsedData?.firstName} ${parsedData?.lastName}`}
                  </Typography>
                  <Box
                    className='flex items-end gap-3 justify-end'
                    sx={{ flexGrow: 1 }}
                  >
                    <CustomButton
                      onClick={() => reportShipmentHandler()}
                      icon={plusGray}
                      text='Test Report Shipment'
                      className={`bg-foundation-white px-[16px]  rounded-[8px] border border-[#D0D5DD] h-[40px] flex justify-between items-center text-[14px] font-[500] mt-4 sm:mt-0  gap-3`}
                    />
                    <div className=''>
                      <InputLabel id='year-select-label'>
                        Select Year
                      </InputLabel>
                      <Select
                        labelId='year-select-label'
                        id='year-select'
                        value={selectedYear}
                        onChange={handleYearChange}
                        MenuProps={StyledMenuProps}
                        sx={{
                          borderRadius: '8px',
                          height: '40px',
                          width: '115px',
                          '.MuiOutlinedInput-notchedOutline': {
                            borderColor: '#D0D5DD',
                          },
                        }}
                      >
                        {yearsFilterArray.map((item, i) => (
                          <MenuItem key={i} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>{' '}
                    <div className=''>
                      <InputLabel id='month-select-label'>
                        Select Month
                      </InputLabel>
                      <Select
                        labelId='month-select-label'
                        id='month-select'
                        value={selectedMonth}
                        onChange={handleMonthChange}
                        MenuProps={StyledMenuProps}
                        sx={{
                          borderRadius: '8px',
                          height: '40px',
                          width: '115px',
                          '.MuiOutlinedInput-notchedOutline': {
                            borderColor: '#D0D5DD',
                          },
                        }}
                      >
                        {monthlyFilter.map((item, i) => (
                          <MenuItem key={i} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>{' '}
                  </Box>
                </Box>
                <Typography sx={dashboardStyles.descriptionText}>
                  Track, manage and forecast your customers and orders.
                </Typography>
              </Box>
              <Box sx={dashboardStyles.container}>
                {[
                  {
                    title: 'Total Shipments',
                    count: data?.shipmentCount ?? 0,
                    percentage: 12,
                  },
                  {
                    title: 'Total Shipment Value',
                    count: `${FORMATE_AMOUNT(
                      data?.shipmentsTotalValue?.toFixed() ?? 0
                    )}`,
                    percentage: 12,
                  },
                  {
                    title: 'Total Shipment Claims',
                    count: `${data?.shipmentClaimsCount?.toFixed() ?? 0}`,
                    percentage: 2,
                  },
                  {
                    title: 'Total Premium Value',
                    count: `${FORMATE_AMOUNT(
                      data?.totalRevenue?.toFixed() ?? 0
                    )}`,
                    percentage: 2,
                  },
                  {
                    title: 'Total Manual Claims',
                    count: `${data?.manualClaimsCount?.toFixed() ?? 0}`,
                    percentage: 2,
                  },
                  // {
                  //   title: 'Trial Shipments',
                  //   count: `${data?.trialShipmentsCount?.toFixed() ?? 0}`,
                  //   percentage: 2,
                  // },
                  {
                    title: 'Rebate',
                    count: `${FORMATE_AMOUNT(
                      (20 / 100) * data?.totalRevenue?.toFixed() ?? 0
                    )}`,
                    percentage: 2,
                  },
                ].map((item, i) => (
                  <Card
                    key={i}
                    sx={{
                      ...dashboardStyles.innerCard,
                      flexBasis: { md: '30%', sm: '45%', xs: '100%' },
                    }}
                  >
                    <Typography sx={dashboardStyles.totalShipmentsText}>
                      {item.title}
                    </Typography>
                    <Box sx={dashboardStyles.countBox}>
                      <Typography sx={dashboardStyles.shipmentCount}>
                        {item.count}
                      </Typography>
                    </Box>
                  </Card>
                ))}
              </Box>
              <Card sx={dashboardStyles.innerCard}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    gap: '10px',
                  }}
                >
                  <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>
                    Overview
                  </Typography>
                </Box>
                <Box pt='20px'>
                  <Box
                    sx={{
                      background: '#F9FAFB',
                      p: '4px',
                      borderRadius: '8px',
                    }}
                  >
                    {[
                      { label: 'Shipments', value: 'shipment' },
                      { label: 'Billing', value: 'billing' },
                      { label: 'Shipment Claims', value: 'shipmentClaims' },
                      { label: 'Manual Claims', value: 'manualClaims' },
                      // { label: 'Payment', value: 'payment' },
                    ].map((item, i) => (
                      <Button
                        key={i}
                        sx={{
                          background:
                            overviewTab === item.value ? '#fff' : 'transparent',
                          color:
                            overviewTab === item.value ? '#344054' : '#667085',
                          fontWeight: 600,
                          fontSize: '14px',
                          '&:hover': {
                            background:
                              overviewTab === item.value
                                ? '#fff'
                                : 'transparent',
                          },
                        }}
                        onClick={() => handleOverviewTab(item.value)}
                      >
                        {item.label}
                      </Button>
                    ))}
                  </Box>
                  <Box>
                    <ReactECharts option={overviewChartOptions} />
                  </Box>
                </Box>
              </Card>
            </Card>
          </Box>
        </CustomContainer>
      )}
    </>
  );
};

export default Dashboard;
