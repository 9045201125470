import { useEffect, useState } from 'react';
import { getAllClaimsQuery } from '../../../api';
import { FORMATE_AMOUNT, FORMATE_DATE } from '../../../helpers/helpers';
import useApi from '../../../hook/useApi';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../../constants/paths';
import { getAllTradeShowClaimQuery } from '../../../api/services/claim.service';

export const useClaims = () => {
  const navigate = useNavigate();

  const [currentTab, setCurrentTab] = useState('claims');

  const [getAllClaims, isLoading, getAllClaimsError, data] =
    useApi(getAllClaimsQuery);
  const [getAllTradeShowClaims, tradeShowLoading, getAllTradeShowError, tradeShowClaimsData] =
    useApi(getAllTradeShowClaimQuery);

  useEffect(() => {
    getAllClaims();
    getAllTradeShowClaims();
  }, []);

  const reportClaimHandler = () => {
    navigate(`${PATHS.REPORT_MANUAL_CLAIM}`);
  };

  const newClaimHandler = (type) => {
    navigate(`${PATHS.CLAIM_FILE}`, { state: type });
  };

  const handleTabSwitch = (tab) => {
    setCurrentTab(tab);
  };

  const currentClaims =
    currentTab == 'claims'
      ? data?.allMyClaims?.claims
      : currentTab == 'tradeShow' ?
        tradeShowClaimsData?.allMyTradeShowClaims
        : data?.allMyClaims?.manualClaims;

  console.log(tradeShowClaimsData, 'tradeShowClaimsData');

  const modifiedData = currentClaims?.map((claim) => {
    const recipient = claim?.filedByUserType === 'User'
      ? claim?.filedByUser?.firstName + ' ' + claim?.filedByUser?.lastName
      : !claim?.filedByEntityMember // this check is for shipment claims which do not have entity member type
        ? claim?.filedByUser?.firstName + ' ' + claim?.filedByUser?.lastName
        : claim?.filedByEntityMember?.firstName +
        ' ' +
        claim?.filedByEntityMember?.lastName;
    console.log(recipient?.includes(undefined), 'recipient')
    return {
      ...claim,
      id: claim?.id,
      recipient: (
        <div className='flex flex-col'>
          {recipient?.includes(undefined) ? `${claim?.EntityMember?.firstName} ${claim?.EntityMember?.lastName}` : recipient}
        </div>
      ),
      from:
        claim?.shipment && claim?.shipment.from && claim?.shipment.from.nickName,
      carrier:
        claim?.shipment && claim?.shipment.carrier && claim?.shipment.carrier.name,
      shipmentType:
        claim?.shipment &&
        claim?.shipment.shipmentType &&
        claim?.shipment.shipmentType.name,
      value: claim?.claimValue && FORMATE_AMOUNT(claim?.claimValue),
      shipValue: claim?.shipment && FORMATE_AMOUNT(claim?.shipment?.totalValue),
      adjusted: claim?.claimAmount ? FORMATE_AMOUNT(claim?.claimAmount) : '-',
      partial: claim?.partToBeClaimed ? true : false,
      filledOn: claim?.createdAt && FORMATE_DATE(claim?.createdAt),
      status: claim?.status,
      createdAt: claim?.createdAt,
    };
  });

  return {
    data: modifiedData,
    isLoading: isLoading || tradeShowLoading,
    reportClaimHandler,
    newClaimHandler,
    currentTab,
    handleTabSwitch,
  };
};
