import React from 'react';
import CustomContainer from '../CustomContainer';
import AddPaymentMethodAlertComponent from '../add-payment-method-alert-component';
import CustomText from '../CustomText';
import CustomHeading from '../CustomHeading';
import CustomIcon from '../../customAssets/CustomIcon';
import { noShipments } from '../../../assets';

const CustomEmptyDataComponent = ({ children, heading, para, loading, noPaymentAlert = true }) => {
  const user = JSON.parse(localStorage.getItem('login-user'));

  return (
    <div className=' p-1'>
      <CustomContainer>
        {children}
        <div className=' p-[24px] mt-[32px] bg-foundational-white rounded-[20px]'>
          {!loading && noPaymentAlert && user?.entity?.isManualPayment === false && (
            <AddPaymentMethodAlertComponent />
          )}
          <CustomContainer className='  min-h-[620px] flex justify-center items-center  '>
            <CustomContainer className='max-w-[399px] min-h-[355px]  w-full flex flex-col items-center'>
              <CustomHeading className='whitespace-nowrap text-large-heading sm:text-medium-heading md:text-large-heading font-roboto font-[500] text-foundation-black '>
                {heading}
              </CustomHeading>
              <CustomText className='text-pure-black  font-[400] font-roboto text-small-para sm:text-medium-para  mt-[8px]'>
                {para}
              </CustomText>
              {!loading && (
                <CustomIcon icon={noShipments} className='mt-[40px] ' />
              )}
            </CustomContainer>
          </CustomContainer>
        </div>
      </CustomContainer>
    </div>
  );
};

export default CustomEmptyDataComponent;
