import LoginPage from './auth/LoginPage';
import CheckEmailPage from './auth/CheckEmailPage';
import ForgetPasswordPage from './auth/ForgetPasswordPage';
import ResetPasswordPage from './auth/ResetPasswordPage';
import SignUpStepperPage from './auth/SignUpStepperPage';

import ClaimsPage from './claims/ClaimsPage';

import ReportShipmentsPage from './shipments/ReportShipmentsPage';
import ShipmentsPage from './shipments/ShipmentsPage';
import EditShipmentPage from './shipments/EditShipmentPage';
import ViewShipmentDetailPage from './shipments/ViewShipmentDetailPage';
import AddFromShipAddressPage from './ship-from/AddFromShipAddressPage';

import Charity from './charity';

import StaffListPage from './staff/StaffListPage';
import RebateProgramme from './rebate-programme';
import NoClaimRewardProgramme from './no-claim-reward-programme';
import ExtendedWarranty from './extended-warranty';
import BillingsPage from './billings';

import HomePage from './home/HomePage';
import TermsAndCondition from './termsAndCondition';
import RefundPolicy from './refundPolicy';
import ProfilePage from './profile';
import PageNotFound from './PageNotFound';

import FineArtTerms from './fine-art-terms';
import FurnitureTermsAndCondition from './furniture-terms-and-condition';
import DashboardPage from "./dashboard/DashboardPage"

import withAuth from './withAuth';
import TradeShowPolicyPage from './trade-show-policy';
import TradeShowCoverageOption from './trade-show-policy/trade-show-coverage-options';
import TradeShowTermsConditionPage from './trade-show-policy/trade-show-terms-condition';
import TradeShowEventDetailsPage from './trade-show-policy/trade-show-event-details';
import AddTradeShowEventsPage from './trade-show-policy/add-trade-show-events';
import TradeShowPaymentPage from './trade-show-policy/trade-show-payment';
import TradeShowAddPaymentMethodPage from './trade-show-policy/trade-show-payment/add-payment-method';

const ProtectedHomePage = withAuth(HomePage);
const ProtectedBillingPage = withAuth(BillingsPage);
const ProtectedTradeShowPolicyPage = withAuth(TradeShowPolicyPage);
const ProtectedTradeShowEventDetailsPage = withAuth(TradeShowEventDetailsPage);
const ProtectedTradeShowCoveragePage = withAuth(TradeShowCoverageOption);
const ProtectedAddTradeShowEvents = withAuth(AddTradeShowEventsPage);
const ProtectedTradeShowPaymentPage = withAuth(TradeShowPaymentPage);
const ProtectedTradeShowAddPaymentMethodPage = withAuth(TradeShowAddPaymentMethodPage);
const ProtectedTradeShowTermsConditionPage = withAuth(TradeShowTermsConditionPage);
const ProtectedClaimsPage = withAuth(ClaimsPage);
const ProtectedReportShipmentsPage = withAuth(ReportShipmentsPage);
const ProtectedViewShipmentDetailPage = withAuth(ViewShipmentDetailPage);
const ProtectedShipmentsPage = withAuth(ShipmentsPage);
const ProtectedEditShipmentPage = withAuth(EditShipmentPage);
const ProtectedProfilePage = withAuth(ProfilePage);

const ProtectedStaffListPage = withAuth(StaffListPage);
const ProtectedAddFromShipAddressPage = withAuth(AddFromShipAddressPage);

export {
  LoginPage,
  ForgetPasswordPage,
  CheckEmailPage,
  ResetPasswordPage,
  SignUpStepperPage,
  // ClaimsPage,
  ProtectedClaimsPage as ClaimsPage,
  // ShipmentsPage,
  ProtectedShipmentsPage as ShipmentsPage,
  // ReportShipmentsPage,
  ProtectedReportShipmentsPage as ReportShipmentsPage,
  // EditShipmentPage,
  ProtectedEditShipmentPage as EditShipmentPage,
  // ViewShipmentDetailPage,
  ProtectedViewShipmentDetailPage as ViewShipmentDetailPage,
  // AddFromShipAddressPage,
  ProtectedAddFromShipAddressPage as AddFromShipAddressPage,
  // StaffListPage,
  ProtectedStaffListPage as StaffListPage,
  RebateProgramme,
  Charity,
  NoClaimRewardProgramme,
  ExtendedWarranty,
  // BillingsPage,
  ProtectedBillingPage as BillingsPage,
  // TradeShowPolicyPage,
  ProtectedTradeShowPolicyPage as TradeShowPolicyPage,
  ProtectedTradeShowCoveragePage as TradeShowCoveragePage,
  ProtectedTradeShowTermsConditionPage as TradeShowTermsConditionPage,
  ProtectedTradeShowEventDetailsPage as TradeShowEventDetailsPage,
  ProtectedAddTradeShowEvents as AddTradeShowEventsPage,
  ProtectedTradeShowAddPaymentMethodPage as TradeShowAddPaymentMethodPage,
  ProtectedTradeShowPaymentPage as TradeShowPaymentPage,
  // HomePage,
  ProtectedHomePage as HomePage,
  TermsAndCondition,
  RefundPolicy,
  // ProfilePage,
  ProtectedProfilePage as ProfilePage,
  PageNotFound,
  FineArtTerms,
  FurnitureTermsAndCondition,
  DashboardPage
};
