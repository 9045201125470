import { useLocation, useNavigate } from "react-router-dom";
import useApi from "../../../../hook/useApi";
import { fetchTradeShowDetailsQuery } from "../../../../api/services/tradeShowPolicy.service";
import { useEffect } from "react";
export const useTradeShowDetails = () => {
    const { state } = useLocation()
    const navigate = useNavigate()
    if (!state?.id || state === null || state === undefined || state?.id === '') {
        navigate(-1)
    }

    const [
        getTradeEventsDetails,
        allTradeEventsLoading,
        allTradeEventsError,
        allTradeEventsData,
    ] = useApi(fetchTradeShowDetailsQuery);

    useEffect(() => {
        if (state?.id) {
            getTradeEventsDetails(parseInt(state?.id));
        }
    }, [state?.id]);

    return {
        data: allTradeEventsData?.TradeEventDetails,
        state,
        navigate
    }
}