import { gql } from "@apollo/client";
import client from "../apollo.";

export const fetchAllSubscriptionPlanQuery = async () => {
  const { data, errors } = await client.query({
    query: gql`
      query allSubscriptionPlans{
        AllSubscriptionPlans
            {
                id
                name
                price
                policyId
                details
            }
        }
      `,
  });
  return { data, errors };
};

export const fetchAllTradeEvents = async () => {
  const { data, errors } = await client.query({
    query: gql`
query GetAllTradeEvents {
  AllTradeEvents {
    id
    name
    venue
    noOfDays
    startDate
    endDate
    createdAt
    status
    notes
    amountPaid
    refundAmount
    certificateId
    isPaid
    subscriptionPlan {
      id
      name
      price
      policyId
    }
  }
}

         `,
  });
  return { data, errors };
}

export const removeTradeShowMutation = async (tradeId) => {
  console.log(tradeId, 'trade id deleted');
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation deleteTradeEvent($removeTradeId: Int!) {
        deleteTradeEvent(id: $removeTradeId) {
          name
        }
      }
    `,
    variables: {
      removeTradeId: tradeId, // Remove the `$` prefix here
    },
  });
  return { data, errors };
};
export const cancelTradeShowMutation = async (id) => {
  console.log(id, 'trade id cancel');
  const { data, errors } = await client.mutate({
    mutation: gql`
        mutation cancelTradeEvent($id: Int!){
          cancelTradeEvent(id: $id){
            name
            isPaid
            status
            startDate
          }
        }
    `,
    variables: {
      id, // Remove the `$` prefix here
    },
  });
  return { data, errors };
};


export const fetchTradeShowDetailsQuery = async (id) => {
  const { data, errors } = await client.query({
    query: gql`
      query TradeEventDetails($id: Int!) {
        TradeEventDetails(id: $id) {
          id
          name
          venue
          startDate
          endDate
          noOfDays
          isPaid
          subscriptionPlan {
            id
            name
            price
            details
          }
          type{
          name
          id
          }
          entityMember {
            firstName
            lastName
            emailAddress
            phoneNumber
            entity {
              name
              address
              EIN
              principalName
              type {
                id
                name
              }
            }
          }
        }
      }
    `,
    variables: {
      id, // Use `id` directly as the variable name
    },
  });

  return { data, errors };
};


export const createTradeShowMutation = async (createTradeEventInput) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation CreateTradeEvent($createTradeEventInput: TradeEventCreateInput!) {
        createTradeEvent(createTradeEventInput: $createTradeEventInput) {
          id
          name
          venue
          startDate
          endDate
        }
      }
    `,
    variables: {
      createTradeEventInput
    }
  });
  return { data, errors };
};

export const payTradeShowMutation = async (payment) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation payforTradeEvent($payment: TradeEventPaymentInput!) {
        payForTradeEvent(payment: $payment) {
          name
        }
      }
    `,
    variables: {
      payment,
    },
  });
  return { data, errors };
};

export const generatePdfQuery = async (id) => {
  console.log(id, 'trade id for PDF generation');
  const { data, errors } = await client.query({
    query: gql`
      query generatePdf($id: Int!) {
        TradeEventInvoice(id: $id) {
          url
        }
      }
    `,
    variables: {
      id,
    },
  });
  return { data, errors };
};

