import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { CustomIcon } from '../../../common/customAssets';
import { menuListData } from './menuListData';

const MenuList = ({
  className = 'flex  w-full min-w-[850px] justify-center items-center gap-[8px]  ',
  closeHamburgerMenu,
}) => {
  const [iconLoaded, setIconLoaded] = useState(false);
  const handleIconLoad = () => {
    setIconLoaded(true);
  };

  const location = useLocation();
  return (
    <ul className={className}>
      {menuListData.map((menu) => {
        return (
          <li
            onClick={closeHamburgerMenu}
            className={` text-pure-white text-small-para rounded-[6px] text-[14px]  cursor-pointer px-[12px] py-[8px]  ${location.pathname.startsWith(menu.link) ? 'bg-[#8E725E]' : ''
              } `}
            key={menu.menuName}
          >
            <Link
              to={menu.link}
              className={`flex items-center gap-[6px]`}
            >
              {location.pathname.startsWith(menu.link) && (
                <CustomIcon
                  icon={menu.icon}
                  onLoad={handleIconLoad}
                />
              )}
              <span style={{ display: iconLoaded ? '' : 'hidden' }}>
                {menu.menuName}
              </span>
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default MenuList;
