import { Grid } from '@mui/material'
import React, { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bed, chair, fineArtsStars, interior } from '../../../assets'
import { CustomHeading } from '../../../common/custom'
import CustomContainer from '../../../common/custom/CustomContainer'
import { CustomIcon } from '../../../common/customAssets'
import { storeTradeShowAction } from '../../../store'
import { useTradeShowCategories } from './useTradeShowCategories'
import { useNavigate } from 'react-router-dom'
import CircularILoader from '../../../common/custom/circularLoader'

const tradeCategories = [
  { icon: chair, title: 'Home Furnishing', value: "New Furniture" },
  { icon: fineArtsStars, title: 'Fine Arts', value: 'Fine Art' },
  { icon: interior, title: 'Interior Designers', value: 'Interior Design' },
  { icon: bed, title: 'Vintage and Collectibles', value: 'Vintage Furniture' },
]

const TradeShowCategories = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const activeCategory = useSelector((state) => state.tradeShow.activeCategory);

  const { allIndustriesData, allTIndustriesLoading } = useTradeShowCategories()

  const updatedTradeCategories = tradeCategories.map(category => {
    const match = allIndustriesData?.allIndustries?.find(industry => industry.name === category.value);
    return {
      ...category,
      id: match ? match.id : null // Add id if match is found, else null
    };
  });
  console.log(activeCategory, 'activeCategory')
  return (
    <Fragment>
      <CustomHeading className='text-foundation-black font-inter text-small-heading font-[600] pb-8 pt-28 text-center w-full h-full'>
        What are you insuring?
      </CustomHeading>
      <CustomContainer className='max-w-[1300px] w-full mx-auto  px-6'>
        {allTIndustriesLoading &&
          <div className='w-full flex justify-center items-center'>
            <CircularILoader />
          </div>
        }
        {(!allTIndustriesLoading || allTIndustriesLoading === undefined) && (allIndustriesData?.allIndustries === undefined || allIndustriesData?.allIndustries?.length === 0) && <p className='text-[#475467] text-center text-lg font-inter'>No industries found</p>}
        {!allTIndustriesLoading && (allIndustriesData?.allIndustries !== undefined && allIndustriesData?.allIndustries?.length > 0) &&
          <Grid container spacing={'24px'}>
            {updatedTradeCategories?.map((item, i) =>
              <Grid item xs={12} md={6} key={i}>
                <div className={`flex flex-col justify-center items-center gap-3 cursor-pointer rounded-xl p-6 ${activeCategory?.id === item?.id ? 'bg-[#667085] border-none' : 'border border-[#D0D5DD]'}`}
                  onClick={() => dispatch(storeTradeShowAction({ key: 'activeCategory', value: item }))}
                >
                  <div className='bg-[#F1EEEC] w-fit rounded-xl p-3 flex justify-center items-center'>
                    <CustomIcon icon={item.icon} />
                  </div>
                  <p className={`${activeCategory?.id === item?.id ? "text-white" : "text-foundation-black"} text-center font-inter font-[600]`}>{item?.title}</p>
                </div>
              </Grid>)}
          </Grid>}
      </CustomContainer>
      <div className='flex justify-center pt-8'>
        <button disabled={activeCategory?.id === null} className={`bg-[#77553D] text-white py-2 px-10  rounded-lg ${!activeCategory || activeCategory === null
          ? 'border-gray-300 bg-gray-300 text-gray-500 cursor-not-allowed'
          : 'border-[#77553D] bg-[#77553D] text-[#FFFFFF]'
          }`}
          onClick={() => {
            if (activeCategory?.id) navigate('trade-show-coverage')
          }}>
          Continue</button>
      </div>
    </Fragment>
  )
}

export default TradeShowCategories