import React from 'react';
import {
  ClientsPanelHeader,
  CustomButton,
  CustomInput,
} from '../../../common/custom';
import CustomContainer from '../../../common/custom/CustomContainer';
import {
  BEAUTIFY_ADDRESS,
  PREMIUM,
  PARSIFY,
  FORMATE_DATE,
} from '../../../helpers/helpers';
import { CustomersDetailModal } from '../../modals';
import { useViewShipmentDetail } from './useViewShipmentDetail';
import FileViewer from './viewShipmentAttachments';
import { PATHS } from '../../../constants/paths';

const ViewShipmentDetail = () => {
  const {
    data,
    register,
    closeCustomerDetailModal,
    getUpdateCustomerDetailData,
    streetPlaceholder,
    customerDetailModalOpen,
    declaredValue,
    navigate,
  } = useViewShipmentDetail();
  const { documents } = data;
  console.log('documents:', documents);
  let cleanDocuments;
  // if (documents) cleanDocuments = JSON.parse(documents.replace(/\\"/g, '"'));
  if (documents) cleanDocuments = JSON.parse(documents);
  console.log('cleanDocuments:', cleanDocuments);

  const premium = PREMIUM(
    data.totalValue,
    data && data.location && data.location.entity && data.location.entity.rate
  );
  const referenceNumber = JSON.parse(data?.to).reference;
  const pickupFormatedDate = FORMATE_DATE(data?.pickUpDate);
  const deliveryFormatedDate = FORMATE_DATE(data?.deliveryDate);

  return (
    <>
      {customerDetailModalOpen && (
        <CustomersDetailModal
          getUpdateCustomerDetailData={getUpdateCustomerDetailData}
          closeCustomerDetailModal={closeCustomerDetailModal}
          data={data}
        />
      )}

      <CustomContainer>
        <ClientsPanelHeader reportShipment/>
        <div className='stepper-cards max-w-[895px] w-full mx-auto p-4 bg-[#F8FAFB] border-[1px] border-[#E7ECF4]'>
          <h1 className='text-[26px] font-semibold mb-8 text-foundation-brown font-inter'>
            View Shipment Details
          </h1>
          <form>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mt-[15px]'>
              <div className='mb-4 '>
                <CustomInput
                  label='Entity'
                  readOnly={true}
                  value={data?.location.name}
                  name='entity'
                />
              </div>

              <div className=''>
                <CustomInput
                  label='Recipient'
                  value={(PARSIFY(data?.to) && PARSIFY(data?.to).name) || '---'}
                  readOnly={true}
                  name='totalValue'
                />
              </div>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mt-[15px]'>
              <div className='mb-4 '>
                <CustomInput
                  label='Country'
                  readOnly={true}
                  value={PARSIFY(data?.to).country}
                  name='country'
                />
              </div>

              <div className=''>
                <CustomInput
                  label='State'
                  value={PARSIFY(data?.to).state}
                  readOnly={true}
                  name='state'
                />
              </div>
            </div>

            <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mt-[15px]'>
              <div className='mb-4 '>
                <CustomInput
                  label='City'
                  readOnly={true}
                  value={PARSIFY(data?.to).city}
                  name='city'
                />
              </div>

              <div className=''>
                <CustomInput
                  label='Zip Code'
                  value={PARSIFY(data?.to).zipCode}
                  readOnly={true}
                  name='zipcode'
                />
              </div>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
              <div className=''>
                <div className='flex flex-col rounded-md relative mt-[5px]'>
                  <CustomInput
                    readOnly={true}
                    label='Pickup Date'
                    name='pickupDate'
                    value={pickupFormatedDate}
                  />
                </div>
              </div>
              <div>
                <div className='flex flex-col rounded-md relative mt-[5px]'>
                  <CustomInput
                    readOnly={true}
                    label='Delivery Date'
                    name='deliveryDate'
                    value={deliveryFormatedDate}
                  />
                </div>
              </div>
            </div>

            <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mt-[15px]'>
              <div className=''>
                <CustomInput
                  label='Merch Type'
                  readOnly={true}
                  value={data?.shipmentType.name}
                  name='merchandiseType'
                />
              </div>
              <div className='md:mt-[]'>
                <CustomInput
                  label='Mover'
                  readOnly={true}
                  value={data?.carrier?.name}
                  name='carrier'
                />
              </div>
            </div>

            <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mt-[15px]'>
              <div className=''>
                <CustomInput
                  label='Declared Value'
                  readOnly={true}
                  value={'$' + data?.totalValue}
                  name='totalValue'
                />
              </div>

              <div className='md:mt-[]'>
                <CustomInput
                  label='Premium'
                  readOnly={true}
                  value={premium}
                  name='carrier'
                />
              </div>
            </div>

            <div className='items-center grid grid-cols-1 sm:grid-cols-1 gap-[16px] mb-[22px] sm:mb-[16x] mt-[15px]'>
              <div
                className=''
                // onClick={() => openCustomerDetailModal()}
              >
                <CustomInput
                  readOnly={true}
                  value={data ? BEAUTIFY_ADDRESS(streetPlaceholder()) : ''}
                  label='Ship To Address'
                />
              </div>
            </div>

            <div className='items-center grid grid-cols-1 sm:grid-cols-2 gap-[16px] mb-[22px] sm:mb-[16x]'>
              <div
                className=''
                // onClick={() => openCustomerDetailModal()}
              >
                <CustomInput
                  readOnly={true}
                  value={data?.from.nickName}
                  label='Ship From Address'
                />
              </div>

              <div
                className=''
                // onClick={() => openCustomerDetailModal()}
              >
                <CustomInput
                  readOnly={true}
                  value={referenceNumber}
                  label='Reference #'
                />
              </div>
            </div>

            <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mt-[10px]'>
              <div className='mb-4 '>
                <CustomInput
                  label='Payment Status'
                  readOnly={true}
                  value={data?.paid === true ? 'Paid' : 'Pending'}
                  name='entity'
                />
              </div>

              <div className=''>
                <CustomInput
                  label='Shipment Status'
                  readOnly={true}
                  value={data?.approved ? 'Approved' : 'Pending'}
                  name='Status'
                />
              </div>
            </div>

            <div className='grid grid-cols-2 md:grid-cols-2 gap-4 mt-[10px]'>
              <div className='mb-4 '>
                <CustomInput
                  label='Confirmation Id'
                  readOnly={true}
                  value={data?.confirmationId || '---'}
                  name='entity'
                />
              </div>
              {data?.additionalNote.length > 0 ? (
                // <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-[10px]">
                <div className='mb-4 '>
                  <CustomInput
                    label='Shipment / Attachment Notes'
                    readOnly={true}
                    value={data?.additionalNote}
                    name='entity'
                  />
                </div>
              ) : (
                // </div>
                ''
              )}
              {/* <div className="">
                <CustomInput
                  label="Declared Value"
                  readOnly={true}
                  value={'$' + declaredValue}
                  name="Status"
                />
              </div> */}
            </div>

            {cleanDocuments?.length > 0 && (
              <div className='grid grid-cols-1 md:grid-cols-1 gap-4 mt-[10px] h-36'>
                <FileViewer files={cleanDocuments} />
              </div>
            )}
            <div className=' flex flex-col md:flex-row gap-[32px] justify-center mt-[32px]'>
              <CustomButton
                type='button'
                onClick={() => {
                  navigate(PATHS.SHIPMENTS);
                }}
                className='bg-none rounded-[8px] border-[1px] border-foundation-brown  text-foundation-brown max-w-[160px] w-full h-[48px]'
                variant='outline'
                text='Cancel'
              />
            </div>
          </form>
        </div>
      </CustomContainer>
    </>
  );
};

export default ViewShipmentDetail;
