import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ClientsPanelHeader, CustomHeading } from '../../../../common/custom';
import CircularILoader from '../../../../common/custom/circularLoader';
import CustomContainer from '../../../../common/custom/CustomContainer';
import { PATHS } from '../../../../constants/paths';
import { storeTradeShowAction } from '../../../../store';
import CoverageDetails from './coverage-details';
import { useTradeShowCoverage } from './useTradeShowCoverage';

const TradeShowCoveragePlans = () => {
    const navigate = useNavigate();

    const [coverageDetails, setCoverageDetails] = useState(null);
    const [isTermsAndConditions, setIsTermsAndConditions] = useState(false);

    const activePlan = useSelector((state) => state.tradeShow.plan);

    const handleClick = () => {
        window.open('https://ceasta.com', '_blank', 'noopener,noreferrer');
    };

    const { allSubscriptionPlansData, allSubscriptionPlansLoading, dispatch } = useTradeShowCoverage()

    const formattedNumber = (number) => (new Intl.NumberFormat('en-US').format(number));
    return (
        <CustomContainer className='max-w-[1300px] w-full mx-auto  px-6 '>
            <ClientsPanelHeader />
            {coverageDetails === null && <div className='flex flex-col items-center gap-8'>
                <div>
                    <CustomHeading className='text-foundation-black font-inter text-small-heading font-[600] pb-2 pt-4 text-center w-full h-full'>
                        Trade Show Property Coverage Options
                    </CustomHeading>
                    <p className='text-[#475467] text-center text-lg font-inter'>
                        With Ceasta Assurance, claims are handled hassle-free from filing to payment, and you can avoid impacting your general liability insurance.
                        For more details, refer to our <span className='font-semibold underline cursor-pointer' onClick={() => navigate('/trade-show-property-coverage/terms-and-condition')}>Trade Show Property Coverage Terms And Conditions</span> or visit our website <span className='text-[#77553D] font-semibold underline cursor-pointer' onClick={handleClick}>Ceasta Assurance</span>.
                    </p>
                </div>
                <div className='flex items-center gap-6 w-full flex-wrap md:flex-nowrap'>
                    {allSubscriptionPlansLoading &&
                        <div className='w-full flex justify-center items-center'>
                            <CircularILoader />
                        </div>
                    }
                    {(!allSubscriptionPlansLoading || allSubscriptionPlansLoading === undefined) && (allSubscriptionPlansData?.AllSubscriptionPlans === undefined || allSubscriptionPlansData?.AllSubscriptionPlans?.length === 0) && <p className='text-[#475467] text-center text-lg font-inter'>No plans found</p>}
                    {!allSubscriptionPlansLoading && (allSubscriptionPlansData?.AllSubscriptionPlans !== undefined && allSubscriptionPlansData?.AllSubscriptionPlans?.length > 0) && allSubscriptionPlansData?.AllSubscriptionPlans?.map((item) => {
                        return (
                            <div style={{ backgroundColor: activePlan?.id === item?.id ? "#E8E8E8" : "" }}
                                key={item?.id}
                                className={`rounded-2xl bg-white p-8 border cursor-pointer flex-grow ${activePlan?.id === item?.id ? "border-[#77553D] bg-[#E8E8E8]" : "border-[#F1EEEC]"}`}
                                onClick={() => {
                                    dispatch(storeTradeShowAction({ key: 'plan', value: item }))
                                }}
                            >
                                <p className='text-xl text-[#667085] text-center'>{item?.name}</p>
                                <p className='text-2xl text-[##77553D] text-center'>${item?.price}/show</p>
                                <ul className='pt-8' style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                                    <li className='text-[#667085] text-base font-inter pb-4'>
                                        ${formattedNumber(item?.details?.singleItemLimit)} limit value per single item
                                    </li>
                                    <li className='text-[#667085] text-base font-inter pb-4'>${formattedNumber(item?.details?.coverageAmount)}.00 coverage for the exhibited items</li>
                                    <li className='text-[#667085] text-base font-inter pb-4'>{item?.id == '1' ? '1-3 Days' : "1-7 days and up to 10days $100 per diem"}</li>
                                    <li className='text-[#667085] text-base font-inter pb-4'>No deductible</li>
                                </ul>
                                <p className='text-[#0B3D91] hover:text-[#082F6B] underline font-semibold cursor-pointer w-fit' onClick={() => setCoverageDetails(item)}>View Coverage Details</p>
                                {/* <button className='bg-[#F5F5F5] text-[#667085] p-2 border border-[#D0D5DD] rounded-lg mt-3 w-full'
                                    onClick={() => {
                                        dispatch(storeTradeShowAction({ key: 'plan', value: item }));
                                        navigate(PATHS.ADDTRADESHOWEVENT)
                                    }}
                                >
                                    Get Trade Policy
                                </button> */}
                            </div>
                        )
                    })}
                </div>
                <div className='w-full'>
                    <div className='flex items-start gap-2'>
                        <input
                            onChange={() => setIsTermsAndConditions(prev => !prev)}
                            checked={isTermsAndConditions}
                            type="checkbox"
                            className="w-4 h-6 rounded border border-solid border-[#77553D] accent-[#77553D]"
                        />
                        <span> I accept Trade Show Property Coverage <span className='font-semibold underline cursor-pointer' onClick={() => navigate('/trade-show-property-coverage/terms-and-condition')}>Terms And Conditions</span></span>
                    </div>
                </div>
                <button className={`bg-[#77553D] text-white py-2 px-10  rounded-lg ${!activePlan || activePlan === null || !isTermsAndConditions
                    ? 'border-gray-300 bg-gray-300 text-gray-500 cursor-not-allowed'
                    : 'border-[#77553D] bg-[#77553D] text-[#FFFFFF]'
                    }`} disabled={activePlan === null || !isTermsAndConditions} onClick={() => navigate(PATHS.ADDTRADESHOWEVENT)}>Continue</button>
            </div>}
            {coverageDetails !== null && <CoverageDetails coverageDetails={coverageDetails} setCoverageDetails={setCoverageDetails} />}
        </CustomContainer>
    )
}

export default TradeShowCoveragePlans