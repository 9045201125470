import { gql } from '@apollo/client';
import client from '../apollo.';

export const fileClaimMutation = async ({
  receipts,
  invoice,
  entityId,
  attachments,
  locationId,
  internalNote,
  shipmentId,
  bestWayToContact,
  customerEmail,
  customerPhone,
  description,
  claimAmount,
  partToBeClaimed,
  internalComments,
  filedByName,
}) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation fileClaimMutation($createClaimInput: ShipmentClaimCreateInput!) {
        createClaim(createClaimInput: $createClaimInput) {
          id
        }
      }
    `,
    variables: {
      createClaimInput: {
        receipts: receipts,
        invoice: invoice,
        attachments: attachments,
        entity: {
          connect: {
            id: entityId,
          },
        },
        location: {
          connect: {
            id: locationId,
          },
        },
        shipment: {
          connect: {
            id: shipmentId,
          },
        },
        filedByUser: {
          connect: {
            id: 0,
          },
        },
        customerNote: internalNote,
        bestWayToContact: bestWayToContact,
        customerEmail: customerEmail,
        customerPhone: customerPhone,
        description: description,
        claimAmount: claimAmount,
        partToBeClaimed: partToBeClaimed,
        internalComments: internalComments,
        filedByName,
      },
    },
  });
  return { data, errors };
};

export const getAllClaimsQuery = async () => {
  const { data, errors } = await client.query({
    query: gql`
      query AllMyClaims {
        allMyClaims {
          claims {
            id
            claimActivities {
              description
              subject
              id
              createdAt
            }
            claimValue
            createdAt
            customerEmail
            customerPhone
            invoice
            receipts
            attachments
            filedByUser {
              id
              lastName
              firstName
            }
            settled
            status
            claimAmount
            partToBeClaimed
            shipment {
              totalValue
              to
              carrier {
                name
              }
              shipmentType {
                name
              }
              from {
                nickName
              }
            }
          }
          manualClaims {
            id
            filedByUser {
              id
              firstName
              lastName
            }
            filedByEntityMember {
              id
              firstName
              lastName
            }
            filedByUserType
            entity {
              name
              id
            }
            createdAt
            claimValue
            status
            claimId
            internalNote
            customerNote
            fileNumber
            referenceNumber
          }
        }
      }
    `,
  });

  return { data, errors };
};

export const createManualClaimMutation = async ({
  receipts,
  invoice,
  entityId,
  attachments,
  internalNote,
  bestWayToContact,
  customerEmail,
  customerPhone,
  description,
  fileNumber,
  referenceNumber,
  claimValue,
  filedByUserId,
  filedByName,
}) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation createManualClaim($createInput: ManualClaimCreateInput!) {
        createManualClaim(createInput: $createInput) {
          id
        }
      }
    `,
    variables: {
      createInput: {
        receipts: receipts,
        invoice: invoice,
        attachments: attachments,
        entity: {
          connect: {
            id: entityId,
          },
        },
        filedByEntityMember: {
          connect: {
            id: filedByUserId,
          },
        },
        customerNote: internalNote,
        bestWayToContact: bestWayToContact,
        customerEmail: customerEmail,
        customerPhone: customerPhone,
        description: description,
        fileNumber: fileNumber,
        referenceNumber: referenceNumber,
        claimValue: claimValue,
        filedByUserType: 'EntityMember',
        filedByName,
        adjustedAmount: 0,
      },
    },
  });
  return { data, errors };
};

export const getClaimDetailsQuery = async (claimId) => {
  const { data, errors } = await client.query({
    query: gql`
      query claim($claimId: Int!) {
        claim(id: $claimId) {
          attachments
          bestWayToContact
          claimValue
          createdAt
          customerEmail
          customerNote
          customerPhone
          description
          claimAmount
          partToBeClaimed
          entity {
            id
            name
          }
          filedByUser {
            firstName
            lastName
            id
          }
          id
          internalNote
          invoice
          location {
            id
            name
          }
          receipts
          settled
          status
          signedReleaseClaim
          claimActivities {
            subject
            description
            id
            createdAt
          }
          shipment {
            id
            from {
              address
              nickName
            }
            to
            totalValue
            receipt
            shipmentType {
              name
              id
            }
            confirmationId
            concludedOn
            carrier {
              name
            }
            approvedOn
            additionalNote
            additionalFiles
            approved
            createdAt
            deliveryDate
            deliveryDocuments
            documents
            hasExpensiveItem
            paid
            paymentStatus
            pickUpDate
            premium
            status
          }
        }
      }
    `,
    variables: {
      claimId: claimId,
    },
  });
  return { data, errors };
};

export const getManualClaimDetailsQuery = async (claimId) => {
  const { data, errors } = await client.query({
    query: gql`
      query manualClaim($claimId: Int!) {
        manualClaim(id: $claimId) {
          attachments
          bestWayToContact
          claimValue
          createdAt
          customerEmail
          customerNote
          customerPhone
          description
          entity {
            id
            name
          }
          filedByUser {
            firstName
            lastName
            id
          }
          claimActivities {
            id
            claimId
            subject
            description
            createdAt
          }
          id
          internalNote
          invoice
          receipts
          settled
          status
          claimId
          signedReleaseClaim
        }
      }
    `,
    variables: {
      claimId: claimId,
    },
  });
  return { data, errors };
};
export const getTradeShowClaimDetailsQuery = async (claimId) => {
  const { data, errors } = await client.query({
    query: gql`
      query tradeShowClaim($claimId: Int!) {
        tradeShowClaim(id: $claimId) {
          attachments
          bestWayToContact
          claimValue
          createdAt
          customerEmail
          customerNote
          customerPhone
          description
          claimActivities {
            id
            claimId
            subject
            description
            createdAt
          }
          id
          internalNote
          invoice
          receipts
          settled
          status
          claimId
          signedReleaseClaim
        }
      }
    `,
    variables: {
      claimId: claimId,
    },
  });
  return { data, errors };
};

export const updateClaimMutation = async ({
  claimId,
  releasefile,
  claimValue,
  customerEmail,
  customerPhone,
  customerNote,
  description,
  receipts,
  invoice,
  attachments,
}) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation Mutation(
        $claimWhereUniqueInput: ShipmentClaimWhereUniqueInput!
        $updateClaimInput: ShipmentClaimUpdateInput!
      ) {
        updateClaimRelease(
          claimWhereUniqueInput: $claimWhereUniqueInput
          updateClaimInput: $updateClaimInput
        ) {
          id
        }
      }
    `,
    variables: {
      claimWhereUniqueInput: { id: claimId },
      updateClaimInput: {
        signedReleaseClaim: {
          set: releasefile,
        },
        claimValue: {
          set: Number(claimValue),
        },
        customerEmail: { set: customerEmail },
        customerPhone: { set: customerPhone },
        customerNote: { set: customerNote },
        description: { set: description },
        receipts: { set: receipts },
        invoice: { set: invoice },
        attachments: { set: attachments },
      },
    },
  });

  return { data, errors };
};

export const updateManualClaimMutation = async ({
  claimId,
  releasefile,
  claimValue,
  customerEmail,
  customerPhone,
  customerNote,
  description,
  receipts,
  invoice,
  attachments,
}) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation Mutation(
        $claimWhereUniqueInput: ManualClaimWhereUniqueInput!
        $updateClaimInput: ManualClaimUpdateInput!
      ) {
        updateManualClaimRelease(
          claimWhereUniqueInput: $claimWhereUniqueInput
          updateClaimInput: $updateClaimInput
        ) {
          id
        }
      }
    `,
    variables: {
      claimWhereUniqueInput: { id: claimId },
      updateClaimInput: {
        signedReleaseClaim: {
          set: releasefile,
        },
        claimValue: {
          set: Number(claimValue),
        },
        customerEmail: { set: customerEmail },
        customerPhone: { set: customerPhone },
        customerNote: { set: customerNote },
        description: { set: description },
        receipts: { set: receipts },
        invoice: { set: invoice },
        attachments: { set: attachments },
      },
    },
  });

  return { data, errors };
};
export const updateTradeShowClaimMutation = async ({
  claimId,
  releasefile,
  claimValue,
  customerEmail,
  customerPhone,
  customerNote,
  description,
  receipts,
  invoice,
  attachments,
}) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation Mutation(
        $tradeShowClaimWhereUniqueInput: TradeShowClaimWhereUniqueInput!
        $updateTradeShowClaimInput: TradeShowClaimUpdateInput!
      ) {
        updateTradeShowClaim(
          tradeShowClaimWhereUniqueInput: $tradeShowClaimWhereUniqueInput
          updateTradeShowClaimInput: $updateTradeShowClaimInput
        ) {
          id
        }
      }
    `,
    variables: {
      tradeShowClaimWhereUniqueInput: { id: claimId },
      updateTradeShowClaimInput: {
        signedReleaseClaim: {
          set: releasefile,
        },
        claimValue: {
          set: Number(claimValue),
        },
        customerEmail: { set: customerEmail },
        customerPhone: { set: customerPhone },
        customerNote: { set: customerNote },
        description: { set: description },
        receipts: { set: receipts },
        invoice: { set: invoice },
        attachments: { set: attachments },
      },
    },
  });

  return { data, errors };
};

export const createTradeShowClaimMutation = async (createTradeShowClaimInput) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
    mutation createTradeShowClaim($createTradeShowClaimInput:TradeShowClaimCreateInput!){
      createTradeShowClaim(
        createTradeShowClaimInput:$createTradeShowClaimInput
      ){
        invoice
      }
    }
    `,
    variables: {
      createTradeShowClaimInput
    }
  })
  return { data, errors };
}
export const getAllTradeShowClaimQuery = async () => {
  const { data, errors } = await client.mutate({
    mutation: gql`
   query findAllTradeShowClaims{
      allMyTradeShowClaims{
        id
        EntityMember{
          firstName
          lastName
          id
        }
        claimActivities{
          description
          subject
        }
        filedByUserType
        createdAt
        claimValue
        invoice
        receipts
        attachments
        status
        claimId
        internalNote
        customerNote
        fileNumber
        referenceNumber
      }
    }
    `,
  })
  return { data, errors };
}